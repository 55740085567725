import { useEffect, useState } from 'react';
import { AppPages } from 'routes';
import { useNavigate } from 'react-router-dom';

import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';

import { useAppAuth } from 'context';
import { resolveS3FileUrl } from 'services';
import Badge from '@mui/material/Badge/Badge';
import { menuStyling } from './styles';
import avatarPlaceholder from 'assets/icons/avatar/ic_avatar.png';

export default function NavBarAccountDropDown() {

    const { appUser } = useAppAuth();

    const [userProfilePicUrl, setUserProfilePicUrl] = useState<string | undefined>(appUser?.profilePicId ? resolveS3FileUrl(appUser.profilePicId) : avatarPlaceholder);

    useEffect(() => {
        if (appUser?.profilePicId) {
            setUserProfilePicUrl(resolveS3FileUrl(appUser.profilePicId));
        };
    }, [ appUser ]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dropDownOpen = Boolean(anchorEl);

    const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClick = () => setAnchorEl(null);
    const handleMenuClose = () => setAnchorEl(null);

    const navigate = useNavigate();
    const handleAccountSettingsClick = () => navigate(AppPages.AccountSettings);

    const { logOut } = useAppAuth();
    const handleLogOutClick = () => {
        logOut();
        navigate(AppPages.Home, { replace: true });
        window.location.reload();
    }
    

    return (
        <>
            <Badge badgeContent={appUser?.toDos?.length || 0} overlap='circular' color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                <Avatar onClick={handleIconClick} sx={{ width: '60px', height: '60px', cursor: 'pointer', objectFit: 'cover' }}>
                    <img src={userProfilePicUrl} alt='profile_pic' style={{ objectFit: 'cover', height: '50px', width: '50px' }}/>
                </Avatar>
            </Badge>
            
            <Menu
                anchorEl={anchorEl}
                open={dropDownOpen}
                onClose={handleMenuClose}
                onClick={handleMenuClick}
                PaperProps={menuStyling}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Badge badgeContent={appUser?.toDos?.length || 0} overlap='circular' color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                    <MenuItem onClick={handleAccountSettingsClick}>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon> 
                        Account
                    </MenuItem>
                </Badge>

                <MenuItem onClick={handleLogOutClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}
