import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { queryClient } from 'init';
import { AppPages } from "routes";
import { useAppAuth } from "context";
import { useEffect } from "react";

export const AuthSuccess = () => {
    
    const location = useLocation();
    const navigate = useNavigate();
    const { loginAccountWithAuthToken, loginRedirectPath, isAuthenticated } = useAppAuth();
    
    const params = new URLSearchParams(location.search);
    const access_token = params.get('access_token');

    useEffect(() => {
        if (access_token) {

            // save access token to cookie
            loginAccountWithAuthToken(access_token);

            // invalidate cache data
            queryClient.invalidateQueries();

            // navigate to home page or page before login
            if (loginRedirectPath) {
                navigate(loginRedirectPath);
            } else {
                navigate(AppPages.Home);
            };
        } else {
            navigate(AppPages.Home);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary" textAlign="center">
                { isAuthenticated ? 'Authentication Success' : 'Authentication Failed' }
            </Typography>
        </Stack>
    );
};