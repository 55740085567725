export const dealRoutes = {
    create: 'deal',
    read: 'deal',
    delete: 'deal',
    share: 'share',
    queryDeals: 'deal/query',
    searchDeals: 'deal/search',
    view: 'deal/view',
    upvote: 'deal/upvote',
    activity: 'deal/activity',
    bookmark: 'deal/bookmark',
    getByAccountBookmark: 'deal/account/bookmark',
    getByAccount: 'deal/account',
    link: 'deal/link',
    popularBrands: 'deal/brand/popular'
};