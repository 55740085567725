import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    FormProvider,
    RHFTextField,
    notifySuccess,
    CMDragAndDrop,
    DragAndDropImage,
    notifyInfo,
} from "components";
import { generateId, sentenceToTitleCase } from "utils";
import { useHandleApiError } from "hooks";
import Card from "@mui/material/Card/Card";
import Grid from '@mui/material/Grid/Grid';
import { useState, useEffect } from 'react';
import { resolveS3FileUrl } from "services";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack/Stack";
import Avatar from "@mui/material/Avatar/Avatar";
import Container from '@mui/material/Container/Container';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { ImageUploadProvider, useImageUpload } from "context";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { BrandAffiliateLinks } from "./ManageBrandAffiliateLinks";
import { BrandReadModel, UpdateBrandRequest, useGetBrand, useUpdateBrand } from "api/brand";
import { UpdateBrandDetails, resolveUpdatedFields, updateBrandDefaultValues, updateBrandDetailsValidationSchema } from "./update-brand-details";


export const ManageBrandDetails = () => {

    const { brandName } = useParams();

    const { data: brandData, isLoading: isFetchingBrand, isError: getBrandIsError, error: getBrandError } = useGetBrand({ brandName: brandName || '' }, !!brandName);
    useHandleApiError(getBrandIsError, getBrandError);
  
    // update brand
    const successCallback = () =>  notifySuccess('Brand successfully updated');
    const { isLoading: isHandlingUpdate, isError, error, mutate: updateTagMutation } = useUpdateBrand(successCallback);
    useHandleApiError(isError, error, undefined);

    const updateBrand = (data: UpdateBrandDetails) => {
        if (data && brandData) {
            updateTagMutation({ ...data, brandName: brandData.name } as UpdateBrandRequest);
        };
    };

    return (
        <ImageUploadProvider>
            {
                !isFetchingBrand && brandData ?
                    <ManageBrandDetailsForm
                        brandData={brandData}
                        updateBrand={updateBrand}
                        isHandlingUpdate={isHandlingUpdate}
                    />
                :
                <>Loading Brand...</>
            }
        </ImageUploadProvider>
    );
};


type ManageBrandDetailsFormProps = {

    brandData: BrandReadModel;

    isHandlingUpdate?: boolean;
    updateBrand: (data: UpdateBrandDetails) => void;
};
export const ManageBrandDetailsForm = ({ brandData, isHandlingUpdate, updateBrand }: ManageBrandDetailsFormProps) => {
    
    // image selection
    const { imageValid, selectedImageUrl, validateSelectedImage, uploadSelectedImage } = useImageUpload();

    const [ brandProfilePicUrl, setBrandProfilePicUrl ] = useState<string | undefined>(brandData.brandPicId ? resolveS3FileUrl(brandData.brandPicId) : undefined);
    useEffect(() => { selectedImageUrl && setBrandProfilePicUrl(selectedImageUrl); }, [selectedImageUrl]);

    const methods = useForm<UpdateBrandDetails>({ resolver: yupResolver(updateBrandDetailsValidationSchema), defaultValues: updateBrandDefaultValues(brandData), mode: 'all' });
    const { handleSubmit, formState: { isSubmitting, isValid, isDirty }, setValue } = methods;

    const handleSelectedFiles = async (file: File) => {
        await validateSelectedImage(file);
        setValue('brandImageId', generateId(), { shouldDirty: true });
    };
    
    const onSubmit = async (data: UpdateBrandDetails) => {

        let imageId = undefined;
        if (selectedImageUrl && imageValid) {
            notifyInfo("uploading image...");
            imageId = await uploadSelectedImage();
        };
        
        if (imageId) {
            data.brandImageId = imageId;
        };

        const updatedFields = resolveUpdatedFields(data, brandData, imageId);
        if (Object.keys(updatedFields).length > 0) {
            updateBrand(data);
        };

        setValue('website', data.website);
        setValue('description', data.description);
        setValue('brandImageId', data.brandImageId);
    };

    return (
        <Container maxWidth='lg'>
            {
                brandData ?
                <>
                    <Helmet>
                        <title> Manage Brand | { sentenceToTitleCase(brandData.name) } </title>
                    </Helmet>

                    <Stack>
                        {
                            brandData &&
                    
                            <Typography variant="h2" sx={{ py: 3, color: 'text.secondary', textAlign: 'center' }}>
                                Manage brand: { sentenceToTitleCase(brandData.name) }
                            </Typography>
                        }
                        
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={5}>
                                <Card>
                                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                        <Stack spacing={3} p={3}>
                                            
                                            <Stack direction='row' justifyContent='center'>
                                                <CMDragAndDrop allowMultiple={false} headerText='Update your brand profile picture below' handleSelectedFiles={(files) => handleSelectedFiles(files[0])}>
                                                    { 
                                                        brandProfilePicUrl ? 
                                                        <DragAndDropImage imageUrl={brandProfilePicUrl} disableRounded /> 
                                                        : 
                                                        <Avatar variant="rounded" sx={{ width: 120, height: 120 }}>
                                                            BrandImage
                                                        </Avatar>
                                                    }
                                                </CMDragAndDrop>
                                            </Stack>

                                            {/* Brand Name */}
                                            <TextField value={sentenceToTitleCase(brandData.name)} label="Brand Name" disabled />
                                            
                                            {/* Brand Website */}
                                            <RHFTextField name="website" label="Brand Website" />
                                
                                            {/* Brand Description */}
                                            <RHFTextField
                                                rows={3}
                                                multiline
                                                name="description"
                                                label='Description'
                                                placeholder='Tell us about your brand.' 
                                            />

                                            <RHFTextField name="apiKey" label="Api Key" helperText='Api Key is required to update brand.' />

                                            <Stack alignItems="flex-end">
                                                <LoadingButton disabled={!(isDirty && isValid)} onClick={() => handleSubmit(onSubmit)()} variant="contained" size="medium" loading={(isSubmitting || isHandlingUpdate)}>
                                                    Update Brand
                                                </LoadingButton>
                                            </Stack>

                                        </Stack>
                                    </FormProvider>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <Card>
                                    <BrandAffiliateLinks brand={brandData} />
                                </Card>
                            </Grid>
                        </Grid>
                    </Stack>
                </>
                :
                <Typography>Loading brand...</Typography>
            }
        </Container>
    );
};