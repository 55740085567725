import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const MyToastContainer = () => {
    return (
        <>
            <ToastContainer
                position={toast.POSITION.TOP_CENTER}
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                limit={1}
            />
        </>
    );  
};