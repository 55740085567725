// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

// ----------------------------------------------------------------------

const RHFErrorTypes = ["pattern", "valueAsNumber", "valueAsDate","setValueAs","disabled","required","min","max","maxLength","minLength","validate","value","shouldUnregister","onChange","onBlur","deps","matches"];

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  onChangeExtension?: (value: any) => void;
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  helperText,
  placeholder,
  onChangeExtension,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...otherFields }, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...otherFields}
            onChange={(_, newValue) => {
              onChange(newValue);
              onChangeExtension && onChangeExtension(newValue);
            }}
            renderInput={(params) => (
              <TextField
                label={label}
                error={!!error}
                placeholder={placeholder}
                helperText={error ? (RHFErrorTypes.indexOf(error.type) > -1 ? error?.message : 'required') : helperText}
                {...params}
              />
            )}
            {...other}
          />
        )
      }}
    />
  );
}
