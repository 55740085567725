// https://create-react-app.dev/docs/adding-custom-environment-variables/
// remember to prefix env variables with 'REACT_APP_'

export const appConfig = {
    app: {
        websiteUrlBase: process.env.REACT_APP_WEBSITE_URL_BASE || 'http://localhost:3000',
    },
    plugsDealApi: {
        baseUrl: process.env.REACT_APP_PLUGS_DEAL_API || 'http://localhost:5050',
    },
    aws: {
        region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIA5UBAQ57TAUISU7UV',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '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',

        awsS3BucketAssets: process.env.REACT_APP_AWS_S3_BUCKET_ASSETS || 'plugs-deal-dev-assets',
        awsCFAssetsDistributionDomain: process.env.REACT_APP_AWS_CF_ASSETS_DISTRIBUTION_DOMAIN || 'https://dgr9i8es1ys6h.cloudfront.net/',
    },
    reCaptcha: {
        siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeL__IoAAAAABw-ru7eI4CmJSg7YXJGBrYJAHYV',
    },
    streamChat: {
        apiKey: process.env.REACT_APP_STREAM_CHAT_API_KEY || 'dt3jefzza92y',
    },
    ipData: {
        apiKey: process.env.REACT_APP_IP_DATA_API_KEY || 'c2cd7a49de60a674251ff0dcc09326c7608eb5f9064f6b63afcce4c5',
    }
};