import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { notifyError } from "components";
import { generateId, validateImage } from "utils";
import { uploadFileToS3 } from "services";
import { appConfig } from 'config';

export interface ImageUploadContextInterface {
    imageValid: boolean;
    selectedImageUrl: string;
    validateSelectedImage: (file: File) => Promise<void>;
    uploadSelectedImage: () => Promise<string | undefined>;
};

const ImageUploadContext = createContext<ImageUploadContextInterface | undefined>(undefined);

const useImageUpload = (): ImageUploadContextInterface => {
    const imageUploadContext = useContext(ImageUploadContext);

    if (imageUploadContext === undefined) {
        throw new Error ("invalid image upload context.");
    };

    return imageUploadContext;
};

const useProvideImageUpload = (): ImageUploadContextInterface => {

    const [imageValid, setImageIsValid] = useState(false);
    const [imageError, setImageError] = useState<string | undefined>(undefined);
    useEffect(() => {if (!imageValid) { notifyError(imageError as string); }}, [imageValid, imageError]);

    const selectedFileRef = useRef<File | undefined>(undefined);
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | undefined>(undefined);

    const validateSelectedImage = async (file: File): Promise<void> => {
        
        try {
            const imageIsValid = await validateImage(file);

            setImageIsValid(imageIsValid);
            imageIsValid && setSelectedImageUrl(URL.createObjectURL(file));

            selectedFileRef.current = file;
        } catch (error) {
            setImageIsValid(false);
            notifyError(error as string);
        }
    };


    const uploadImage = async (): Promise<string | undefined> => {
        
        const s3ProfilePicId = generateId();
        
        try {
            await uploadFileToS3({ bucketName: appConfig.aws.awsS3BucketAssets, fileId: s3ProfilePicId, fileToUpload: selectedFileRef.current! });
            return s3ProfilePicId;
        } catch (error) {
            setImageIsValid(false);
            setImageError("Unable to upload selected image. Please refresh your browser or try with a different image.");
        };
    };

    return {
        imageValid,
        selectedImageUrl,
        validateSelectedImage,
        uploadSelectedImage: uploadImage
    } as ImageUploadContextInterface;
};

const ImageUploadProvider = ({children}: {children: React.ReactNode}) => {
    const signUpContextValue = useProvideImageUpload();
    
    return (
        <>
            <ImageUploadContext.Provider value={signUpContextValue}>
                {children}
            </ImageUploadContext.Provider>
        </>
    );
};

export { ImageUploadProvider, useImageUpload };