import { AppPages } from 'routes';
import {  useNavigate } from 'react-router-dom';

import logo from 'assets/logo/logo_no_name.png';

import { useAppAuth } from 'context';
import NavBarAccountDropDown from './NavBarAccountDropDown';

import { AuthButton } from 'features/auth';
import { Divider, Stack, Typography } from '@mui/material';

export const NavBar = () => {

    const { isAuthenticated } = useAppAuth();
    const navigate = useNavigate();

    const goHome = () => navigate(AppPages.Home);

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' m={1} alignContent='center'>
                <Stack onClick={goHome} direction='row' justifySelf='flex-start' justifyContent='flex-start' alignContent='center' sx={{ height: '80px', cursor: 'pointer' }}>
                    <img src={logo} alt="the_plug_logo" hidden/>
                    <Typography alignSelf='center' justifySelf='flex-start' variant='h2' sx={{ color: '#A2C1E7', mt: 1, ml: 0,  }}>The Plug</Typography>
                </Stack>

                <Stack alignSelf='center' mr={3}>
                    {
                        isAuthenticated ? 
                        <NavBarAccountDropDown />
                        :
                        <AuthButton />
                    }
                </Stack>
            </Stack>

            <Divider />
        </Stack>
    );
};