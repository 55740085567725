import { useHandleApiError, useResponsive } from 'hooks';
import { fShortenNumber, sentenceToTitleCase } from 'utils';
import { CountDownLabelLite, Iconify, TextMaxLine } from 'components';
import { DealReadModel } from 'api/deal';
import Stack from '@mui/material/Stack/Stack';
import { Avatar, Typography } from '@mui/material';
import { resolveS3FileUrl } from 'services';
import { useGetBrand } from 'api/brand';

export type DealsListCardProps = {
  deal: DealReadModel;
};

const DEALS_INFO = (deal: DealReadModel) => [
    // { id: 'view', value: deal.views, icon: 'eva:eye-fill' },
    { id: 'upvote', value: deal.upvotes, icon: 'mdi:arrow-up-bold' },
];

export const DealsCardContent = ({ deal }: DealsListCardProps) => {

    const { description } = deal;

    const isDesktop = useResponsive('up', 'md');

    const { data: brandData, isLoading: isFetchingBrand, isError: getBrandIsError, error: getBrandError } = useGetBrand({ brandName: deal.brand}, !!deal);
    useHandleApiError(getBrandIsError, getBrandError);

    return (
        <Stack sx={{ mx: 2, my: 1 }} direction='column' spacing={1}>
            <Stack direction='row' alignContent='center'>
                { (brandData && !isFetchingBrand) && <Avatar variant='rounded' src={resolveS3FileUrl(brandData?.brandPicId)} alt=""  sx={{ width: 32, height: 32, mr: 1, }} /> }
                <Typography variant='body1' sx={{ color: 'text.secondary', mt: 0.7 }}>
                    { sentenceToTitleCase(deal.brand) }
                </Typography>
            </Stack>

            <TextMaxLine variant={isDesktop ? 'subtitle1' : 'subtitle2'} line={1} sx={{ overflowWrap: "break-word" }}> 
                { description }
            </TextMaxLine>

            <Stack flexWrap="wrap" direction="row" justifyContent='space-between' sx={{ mt: 1, color: 'text.disabled' }} >
                <CountDownLabelLite endDateTime={deal.endDate} />
                {
                    DEALS_INFO(deal).map((info) => (
                        <Stack
                            id={`un-deal-details-${info.id}-count`}
                            key={info.id}
                            direction="row"
                            alignItems="center"
                            sx={{ typography: 'caption', ml: info.id === 'upvotes' ? 1.0 : 1.5, }}
                        >
                            <Iconify icon={info.icon} width={info.id === 'upvotes' ? 24 : 16 } sx={{ mr: 0.3 }} />
                            {fShortenNumber(info.value) || 0}
                        </Stack>
                    ))
                }
            </Stack>
        </Stack>
    );
};