import { pathToUrl } from 'utils';
import { brandRoutes } from './routes';
import { ApiMessageResponse, SearchResults } from '../interface';
import { useDelete, useFetch, usePost, usePut } from '../apiClient';
import {
    AddBrandAffiliateRequest,
    BrandReadModel,
    BrandRequest,
    CreateBrandRequest,
    GetPopularBrandsRequest,
    RemoveBrandAffiliateRequest,
    SearchBrandsRequest,
    UpdateBrandRequest,
} from "./interface";

export * from './interface';

// mutations and actions
export const useCreateBrand = (successCallback: (resp: BrandReadModel) => void) => 
    usePost<CreateBrandRequest, BrandReadModel>(pathToUrl(brandRoutes.create), undefined, successCallback);

export const useUpdateBrand = (successCallback: (resp: BrandReadModel) => void) => 
    usePut<UpdateBrandRequest, BrandReadModel>(pathToUrl(brandRoutes.update), undefined, successCallback);
    
export const useDeleteBrand = (successCallback: (resp: ApiMessageResponse) => void) => 
    useDelete<BrandRequest, ApiMessageResponse>(pathToUrl(brandRoutes.delete), undefined, successCallback);

export const useAddBrandAffiliate = (successCallback: (resp: BrandReadModel) => void) => 
    usePost<AddBrandAffiliateRequest, BrandReadModel>(pathToUrl(brandRoutes.affiliate), undefined, successCallback);

export const useDeleteBrandAffiliate = (successCallback: (resp: BrandReadModel) => void) => 
    useDelete<RemoveBrandAffiliateRequest, BrandReadModel>(pathToUrl(brandRoutes.affiliate), undefined, successCallback);


// queries
export const useGetBrand = (req: BrandRequest, isEnabled?: boolean) => 
    useFetch<BrandReadModel>(req.brandName ? pathToUrl(brandRoutes.read) : null, req, isEnabled);

export const useSearchBrands = (req: SearchBrandsRequest) => 
    useFetch<SearchResults<BrandReadModel>>(req.q && pathToUrl(brandRoutes.search), req);

export const useGetPopularBrands = (req: GetPopularBrandsRequest, isEnabled?: boolean) => 
    useFetch<BrandReadModel[]>(req.country && pathToUrl(brandRoutes.popularBrands), req, isEnabled);

export const useSearchTags = (req: { q: string }, isEnabled?: boolean) => 
    useFetch<string[]>(req.q && pathToUrl(brandRoutes.searchTags), req, isEnabled);