import { pathToUrl } from 'utils';
import { dealRoutes } from './routes';
import { ApiMessageResponse, SearchResults } from '../interface';
import { usePost, useFetch, useDelete } from '../apiClient';
import {
  CreateDealRequest,
  DealActivityReadModel,
  DealReadModel,
  DealRequest,
  DealShareRequest,
  GetAccountDealsRequest,
  GetDealLinkResponse,
  GetDealLinksRequest,
  QueryDealsRequest,
  DeleteDealRequest,
} from "./interface";

export * from './interface';

// mutations and actions

export const useCreateDeal = (successCallback: (resp: ApiMessageResponse) => void) => 
    usePost<CreateDealRequest, ApiMessageResponse>(pathToUrl(dealRoutes.create), undefined, successCallback);

export const useDeleteDeal = (successCallback: (resp: ApiMessageResponse) => void) => 
    useDelete<DeleteDealRequest, ApiMessageResponse>(pathToUrl(dealRoutes.delete), undefined, successCallback);

export const useRecordDealView = (successCallback: (resp: DealActivityReadModel) => void) => 
    usePost<DealRequest, DealActivityReadModel>(pathToUrl(dealRoutes.view), undefined, successCallback);

export const useRecordUpvoteDeal = (successCallback: (resp: DealActivityReadModel) => void) => 
    usePost<DealRequest, DealActivityReadModel>(pathToUrl(dealRoutes.upvote), undefined, successCallback);

export const useRemoveDealUpvote = (successCallback: (resp: DealActivityReadModel) => void) => 
    useDelete<DealRequest, DealActivityReadModel>(pathToUrl(dealRoutes.upvote), undefined, successCallback);

export const useBookmarkDeal = (successCallback: (resp: DealActivityReadModel) => void) => 
    usePost<DealRequest, DealActivityReadModel>(pathToUrl(dealRoutes.bookmark), undefined, successCallback);

export const useRemoveDealBookmark = (successCallback: (resp: DealActivityReadModel) => void) => 
    useDelete<DealRequest, DealActivityReadModel>(pathToUrl(dealRoutes.bookmark), undefined, successCallback);

export const useRecordDealShare = (successCallback: (resp: DealActivityReadModel) => void) => 
    usePost<DealShareRequest, DealActivityReadModel>(pathToUrl(dealRoutes.share), undefined, successCallback);

// queries

export const useGetDeal = (req: DealRequest, isEnabled?: boolean) => 
    useFetch<DealReadModel>(req.dealId ? pathToUrl(dealRoutes.read) : null, req, isEnabled);

export const useGetDealLink = (req: GetDealLinksRequest, isEnabled?: boolean) => 
    useFetch<GetDealLinkResponse>(req.dealId ? pathToUrl(dealRoutes.link) : null, req, isEnabled);

export const useGetDealActivity = (req: DealRequest) => 
    useFetch<DealActivityReadModel>(req.dealId && pathToUrl(dealRoutes.activity), req);

export const useGetDealsByAccount = (req: GetAccountDealsRequest) => 
    useFetch<SearchResults<DealReadModel>>(pathToUrl(dealRoutes.getByAccount), req);

export const useGetDealsByAccountBookmarks = (req: GetAccountDealsRequest) => 
    useFetch<SearchResults<DealReadModel>>(pathToUrl(dealRoutes.getByAccountBookmark), req);

export const useSearchDeals = (req: QueryDealsRequest, isEnabled?: boolean) => 
    useFetch<SearchResults<DealReadModel>>(req && pathToUrl(dealRoutes.searchDeals), req, isEnabled);