const DASHBOARD_PREFIX = '/dashboard/v1/4F7xP2';
export const AppPages = {

    // ------ app pages ------ //

    // deals
    Home: '/',
    ViewDeal: '/:dealId',

    // account
    AccountSettings: '/a/settings',

    // auth
    AuthSuccess: '/auth-success',

    // admin
    NotFound: '*',
    TermsAndCondition: '/terms-and-conditions',

    // ------ dashboard pages ------ //

    // admin
    Health: `${DASHBOARD_PREFIX}/health`,
    
    // deal
    CreateDeal: `${DASHBOARD_PREFIX}/deal/create`,

    // brand
    CreateBrand: `${DASHBOARD_PREFIX}/brand/create`,
    ManageBrandDetails: `${DASHBOARD_PREFIX}/brand/manage/:brandName`,
};