import { useEffect } from "react";
import { AxiosError } from "axios";
import { notifyError } from "components";
import { useNavigate } from "react-router-dom";
import { ApiErrorResponse } from "../api/apiClient";

export const useHandleApiError = (
    isError: boolean,
    error: AxiosError<ApiErrorResponse, any> | null,
    redirectPath?: string,
    errorCallback?: () => void,
    ) => {

    const navigate = useNavigate();

    useEffect(() => { 
        if (isError && error) {
            const errorMessage  = 
            error.response?.data &&
            error.response?.data.message ? 
            error.response?.data.message.toString() : 'Something went wrong. Please refresh your browser and try again';
                
            notifyError(errorMessage);
            if (redirectPath) {
                navigate(redirectPath);
            };

            errorCallback && errorCallback();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, isError]);
};