import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { Iconify, MenuPopover } from "components";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { snakeToTitle } from "utils";

type SelectSortProps = {
    name: string;
    label:string;
    options: string[];
}
export const SelectSort = ({ name, options, label }: SelectSortProps) => {

  // sort options
  const stateOptions = Object.values(options);

  // resolve form context
  const { control } = useFormContext();

  // handle sort pop over
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const handleClosePopover = () => setOpenPopover(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => setOpenPopover(event.currentTarget);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Button
            disableRipple
            color="inherit"
            onClick={handleOpenPopover}
            sx={{ fontWeight: 'fontWeightMedium' }}
            endIcon={ <Iconify icon={openPopover ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} /> }
          >
            { label }:
            <Box component="span" sx={{ color: 'text.secondary', ml: 0.5, textTransform: 'capitalize' }}>
              {snakeToTitle(field.value)}
            </Box>
          </Button>

          <MenuPopover open={openPopover} onClose={handleClosePopover}>
            {
              stateOptions.map((option) => (
                <MenuItem 
                  key={option} 
                  onClick={() => {
                    handleClosePopover();
                    field.onChange(option);
                  }}
                  selected={option === field.value}
                  sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                >
                  {snakeToTitle(option)}
                </MenuItem>
              ))
            }
          </MenuPopover>
        </>
      )}
    />
  );
}
