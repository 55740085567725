import Box from "@mui/material/Box/Box";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import Skeleton from "@mui/material/Skeleton/Skeleton";
import Stack from "@mui/material/Stack/Stack";
import TableCell from "@mui/material/TableCell/TableCell";
import TableRow from "@mui/material/TableRow/TableRow";
import Typography from "@mui/material/Typography/Typography";

export const SettingsSkeleton = () => {
    return (
        <Box>
            <Skeleton variant="text" width="100%"   sx={{ fontSize: '2rem' }} />

            <Stack direction="row" spacing={1} >
                <Skeleton variant="rounded" width="100%" height={240} />
            </Stack>
        </Box>
    )
};

export const ProfileSkeleton = () => {
  return (
    <Box>
      <Stack direction="row" spacing={1} >
        <Skeleton variant="rounded" width="100%" height={240} />
      </Stack>
    </Box>
  )
};

export const ProfileDetailsLoading = () => {
  return (
    <Stack spacing={3}>
      <Skeleton variant="rectangular" sx={{ height: 120, borderRadius: 2 }} />
      <Skeleton variant="rectangular" sx={{ height: 120, borderRadius: 2 }} />
    </Stack>
  )
};


export const AccountsWithStatusTableSkeleton = () => {
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <Stack spacing={3} direction="row" alignItems="center">
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            sx={{ borderRadius: 1, flexShrink: 0 }}
          />
          <Skeleton variant="text" width='100%' height={80} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export const DealDetailSkeleton = () => {
  return (
    <Container>
      <Grid container spacing={3} sx={{ mt: 3, mb: 1 }}>
        <Grid item xs={12} md={7}>
          <Skeleton variant="rectangular" width="100%" sx={{ height:520, borderRadius: 2, mb: 3 }} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Skeleton variant="rectangular" width="100%" sx={{ height: 360, borderRadius: 2 }} />
        </Grid>
      </Grid>
    </Container>
  )
};

export const MessagesLoadingIndicator = () => {
  return (
    <>
      <Stack justifyContent='center' direction='row'>
        <CircularProgress sx={{ m: 3 }} />
      </Stack>
      <Stack justifyContent='center' direction='row'>
        <Typography sx={{ color: 'text.secondary' }}>Loading messages...</Typography>
      </Stack>
    </>
  )
}

export const DealsListSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" width="100%" height={340} />
      <Skeleton variant="rounded" width="100%" height={340} />
      <Skeleton variant="rounded" width="100%" height={340} />
      <Skeleton variant="rounded" width="100%" height={340} />
    </>
  )
};