import { Suspense } from 'react';
import { queryClient } from '../init';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MyThemeProvider } from './theme/theme-provider';
import { AppAuthProvider } from './app-auth-context';
import { QueryClientProvider } from '@tanstack/react-query';
import { FullPageSpinner, GlobalErrorBoundary } from 'components';
import { AppLocationProvider } from './app-location-context';


export const AppProviders = ({children}: {children: React.ReactNode}) => {
    return (
        <>
            <MyThemeProvider>
                <HelmetProvider>
                    <BrowserRouter>
                        <GlobalErrorBoundary>
                            <QueryClientProvider client={queryClient}>
                                <AppAuthProvider>
                                    <AppLocationProvider>
                                        <Suspense fallback={<FullPageSpinner />} >
                                            {children}
                                        </Suspense>
                                    </AppLocationProvider>
                                </AppAuthProvider>
                            </QueryClientProvider>
                        </GlobalErrorBoundary>
                    </BrowserRouter>
                </HelmetProvider>
            </MyThemeProvider>
        </>
    );
};