import { onAppError } from 'utils';
import { ErrorBoundary } from 'react-error-boundary';

import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';
import SeverErrorIllustration from '../assets/illustrations/SeverErrorIllustration';

export const ServerError = ({ error }: any) => {
  return (
    <>
        <Helmet>
            <title> 500 Internal Server Error | The Plug</title>
        </Helmet>

        <Container maxWidth='lg'>
            <Stack m={3} alignItems='center'>
                <Typography variant="h2" paragraph>
                    500 Internal Server Error
                </Typography>

                <Typography sx={{ color: 'text.secondary' }}>
                    There was an internal error, we are looking into it. Please try again later.
                </Typography>

                <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

                <Button component={RouterLink} to="/" size="large" variant="contained">
                    Go Home
                </Button>
            </Stack>
        </Container>
    </>
  );
}

export const GlobalErrorBoundary = ({children}: {children: React.ReactNode}) => {
    return (
        <>
            <ErrorBoundary FallbackComponent={ServerError} onError={onAppError}>
                {children}
            </ErrorBoundary>
        </>
    );
};