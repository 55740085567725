import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";

type DealsListGridType = {
    children: React.ReactNode;
};

export const DealsListGrid = ({ children }: DealsListGridType) => {
    return (
        <Container maxWidth='lg'>
            <Box
                gap={2}
                display="grid"
                mt={3}
                gridTemplateColumns={{
                    xs: 'repeat(2, 1fr)',
                    sm: 'repeat(3, 1fr)',
                    lg: 'repeat(4, 1fr)',
                }}
            >
                { children }
            </Box>
        </Container>
    )
};