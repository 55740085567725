import { useEffect } from "react";
import { AppPages } from "routes";
import { useAppAuth } from "context";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const ProtectedRoute = (): React.ReactElement => {

    const { isAuthenticated, setLoginRedirectPath } = useAppAuth();

    const { pathname } = useLocation();

    useEffect(() => {
        // if account is not logged in, save redirect path for use post login
        if (!isAuthenticated) {
            setLoginRedirectPath(pathname)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pathname ]);

    if (isAuthenticated === true) {
        return <Outlet />;
    };
    
    return <Navigate to={AppPages.Home} replace={true} />;
};