import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";


import { 
    DeleteAccountDetails,
    deactivateAccountDetailSchema,
    deactivateAccountDetailActivationValues,
} from "./settings-data";
import { AppPages } from "routes";
import { useHandleApiError } from "hooks";
import { AppUser, useAppAuth } from "context";
import { ApiMessageResponse } from "api/interface";
import { CMTextField, ConfirmDialog, UNTypography, notifySuccess } from 'components';
import Card from "@mui/material/Card/Card";
import Stack from "@mui/material/Stack/Stack";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useDeleteAccount } from "api/account";
import { useState } from "react";
import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";

export const AccountSecurity = () => {
    
    const navigate = useNavigate();
    const { appUser, logOut }= useAppAuth();

    const handleLogOut = () => {
        logOut();
        navigate(AppPages.Home, { replace: true });
        window.location.reload();
    };
    
    // delete account
    const deactivateAccountSuccessCallback = async (response: ApiMessageResponse) => {
        notifySuccess(response.message);
        handleLogOut();
    };
    const { isLoading: isLoadingDA, isError: isErrorDA, error: errorDA, mutate: deactivateAccountMutation } = useDeleteAccount(deactivateAccountSuccessCallback);
    useHandleApiError(isErrorDA, errorDA);
    const handleDeactivateAccount = (values: DeleteAccountDetails) => deactivateAccountMutation({ email: values.email });

    return (
        <AccountSecurityForm
            appUser={appUser}

            isHandlingdeleteAccount={isLoadingDA}
            handledeleteAccount={handleDeactivateAccount}
        />
    );
};

type AccountSecurityFormProps = {
    appUser: AppUser;
    isHandlingdeleteAccount?: boolean;
    handledeleteAccount: (values: DeleteAccountDetails) => void;
};

export const AccountSecurityForm = ({ appUser, isHandlingdeleteAccount, handledeleteAccount, }: AccountSecurityFormProps) => {

    const [openConfirm, setOpenConfirm] = useState(false);
    const handleCloseConfirm = () => setOpenConfirm(false);
    const handleOpenConfirm = async () => setOpenConfirm(true);

    // delete account
    const formikDA = useFormik<DeleteAccountDetails>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: deactivateAccountDetailActivationValues,
        validationSchema: deactivateAccountDetailSchema,
        onSubmit: async (values: DeleteAccountDetails) => await handledeleteAccount(values),
    });

    return (
        <Card sx={{ p: 3, textAlign: 'center' }}>
            <UNTypography
                style={{ margin: "0 0 11px 0", color: 'red' }}
                variant="subtitle1"
                content="Be very careful here. Deleting your account is irreversible. Once it is deleted, it is truly deleted!"
                textColor="var(--dim-color)"
            />

            <Stack alignItems="flex-end">
                <CMTextField
                    variant="filled"
                    type="text"
                    name="email"
                    label="Email"
                    onBlur={formikDA.handleBlur}
                    onChange={formikDA.handleChange}
                    value={formikDA.values.email}
                    InputProps={{ disableUnderline: true }}
                    helperText={formikDA.touched.email && formikDA.errors.email}
                    error={formikDA.touched.email && Boolean(formikDA.errors.email)}
                />

                <CMTextField
                    variant="filled"
                    color='error'
                    type="text"
                    name="delete"
                    label={ <> <em>delete</em> </> }
                    onBlur={formikDA.handleBlur}
                    onChange={formikDA.handleChange}
                    value={formikDA.values.delete}
                    InputProps={{ disableUnderline: true }}
                    helperText={formikDA.touched.delete && formikDA.errors.delete}
                    error={ formikDA.touched.delete && Boolean(formikDA.errors.delete) }
                />
                            
                <Button 
                    type='button' 
                    variant="contained" 
                    onClick={handleOpenConfirm} 
                    size="medium"
                    color='error'
                    disabled={!(formikDA.isValid && formikDA.dirty)}
                >
                    Delete My Account
                </Button>


                {/* Delete Account Confirmation */}
                <ConfirmDialog
                    open={openConfirm}
                    title="Create Deal?"
                    onClose={handleCloseConfirm}
                    content={
                        <>
                            <Typography gutterBottom>Are you sure you want to Delete your account?</Typography>
                            <i>PS: This is not a joke. We truly delete all of your records from our database!</i> 
                        </>
                    }
                    action={
                        <LoadingButton
                            color='error'
                            variant="contained"
                            onClick={() => formikDA.submitForm()}
                            disabled={!(formikDA.isValid && formikDA.dirty)}
                            size="large" loading={formikDA.isSubmitting || (isHandlingdeleteAccount === true)}
                        >
                            Yes, Delete! My Account!
                        </LoadingButton>
                    }
                />
            </Stack>
        </Card>
    );
};