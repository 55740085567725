import { CardActionArea, CardMedia, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar/Avatar";
import Box from "@mui/material/Box/Box";
import Card from "@mui/material/Card/Card";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { DealReadModel, DealTypes, GetDealLinkResponse } from "api/deal";
import { CountDownLabel, Label, TextMaxLine, notifyInfo } from 'components';
import { resolveS3FileUrl } from "services";
import { fToNow, isInFuture, sentenceToTitleCase, snakeToTitle } from "utils";
import Link from '@mui/material/Link/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton/IconButton';
import { BrandReadModel } from "api/brand";

type MainDealViewProps = {
    dealsBrand: BrandReadModel;
    currentDeal: DealReadModel;
    dealLink: GetDealLinkResponse;
};
export const MainDealView = ({ currentDeal, dealLink, dealsBrand }: MainDealViewProps) => {

    const redirectToDealSite = () => window.open(`https://www.${dealLink.website}`);

    const handleClickToCopy = () => {
        navigator.clipboard.writeText(currentDeal.dealCode!).then(x => {
            notifyInfo(`Code Copied! Redirecting to ${currentDeal.brand}`);
            redirectToDealSite();
        });
    };

    const redirectToBrandLink = () => {
        return (
            <Stack direction='row' onClick={redirectToDealSite}>
                <Typography variant="h6" gutterBottom> 
                    Go to
                    <Link sx={{ color: '#DC143C' }} onClick={redirectToDealSite} variant="h4" underline='always' mr={1}>
                        {` ${sentenceToTitleCase(dealLink.brandName)}`}    
                    </Link>
                    Now
                </Typography>
            </Stack>
        );
    };

    return (
        <Card sx={{ p: 3, alignItems: 'center', cursor: 'pointer' }}>

            <Stack direction='column' alignItems="center" mb={2} justifyContent='flex-start' spacing={2}>
                
                <Typography variant='subtitle1' sx={{ color: 'text.secondary' }}>
                    { snakeToTitle(currentDeal.type) }
                </Typography>

                <Box 
                //  sx={{ maxWidth: 440 }}
                 onClick={redirectToDealSite}
                >
                    <CardActionArea>
                        <CardMedia component="img" height="320" image={resolveS3FileUrl(currentDeal.dealPicId)} alt="deal_image" sx={{ objectFit: 'contain', }} />
                    </CardActionArea>
                </Box>

                {/* Deal Brand */}
                <Stack onClick={redirectToDealSite} direction='row' alignContent='center'>
                    { dealsBrand && <Avatar variant='rounded' src={resolveS3FileUrl(dealsBrand.brandPicId)} alt=""  sx={{ width: 28, height: 28, mr: 1 }} /> }
                    
                    <Typography variant='body1' sx={{ color: 'text.secondary', mt: 0.7 }}>
                        { sentenceToTitleCase(currentDeal.brand) }
                    </Typography>
                </Stack>
            </Stack>
    
            <Stack spacing={1} mx={1} direction="column" alignItems="center" sx={{ color: 'text.secondary' }} justifyContent='center'>

                <TextMaxLine variant="h4" line={1} sx={{ mt: 1, overflowWrap: "break-word", color: 'text.secondary' }}> 
                    { currentDeal.description }
                </TextMaxLine>
                
                <Divider />

                <Stack direction='column' spacing={2}>
                    {
                        (currentDeal.type === DealTypes.OnlineCode) &&
                        <>
                            {/* Deal Code */}
                            <Stack spacing={1} direction='column' alignItems="center"  mb={2} justifyContent='center'>

                                
                                <Label onClick={handleClickToCopy} variant='soft' sx={{ color: '#DC143C', height: 48, cursor: 'pointer' }} >
                                    <Typography mx={1} sx={{ color: '#DC143C' }} variant="h4"> {currentDeal.dealCode} </Typography>
                                    
                                    <IconButton edge="end" >
                                        <ContentCopyIcon sx={{ color: '#DC143C' }} />
                                    </IconButton>
                                </Label>
                                
                                <Stack direction='row'>
                                    <Typography variant="h6" gutterBottom> 
                                        Copy the code and paste at
                                        <Link sx={{ color: '#DC143C' }} onClick={redirectToDealSite} variant="h6" underline='always'>
                                            {` ${sentenceToTitleCase(dealLink.brandName)}`}    
                                        </Link>
                                    </Typography>
                                </Stack>
                
                                <Divider />
                            </Stack>
                        </>
                    }

                    {
                        (currentDeal.type === DealTypes.CashBack) &&
                        <Stack spacing={1} direction='column' alignItems="center" mb={2} justifyContent='center'>
                            <Typography sx={{ textAlign: 'center' }} onClick={redirectToDealSite} variant="h6" gutterBottom> 
                                Activate Deal and Go to 
                                <Link sx={{ color: '#DC143C', ml: 1 }} variant="h6" underline='always'>
                                    {sentenceToTitleCase(dealLink.brandName)}
                                </Link>
                            </Typography>
                
                            <Divider />
                        </Stack>
                    }

                    {
                        (currentDeal.type === DealTypes.OnlineSaleTip) &&
                        <Stack spacing={1} direction='column' alignItems="center"  mb={2} justifyContent='center'>
                            { redirectToBrandLink() }
                
                            <Divider />
                        </Stack>
                    }
                </Stack>
            </Stack>
    
            <Stack justifyContent='center' mt={0} mx={1} direction="row" alignItems="center" sx={{ color: 'text.secondary' }}>
                {
                    isInFuture(currentDeal.endDate) ?
                    <CountDownLabel endDateTime={currentDeal.endDate} />
                    :
                    <Stack>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="subtitle2" gutterBottom> 
                            Deal ended { fToNow(currentDeal.endDate) }
                        </Typography>
                    </Stack>
                }
            </Stack>
        </Card>
    );
};