import { useEffect, useState } from 'react';
import { RHFAutocomplete } from 'components';
import { useHandleApiError } from 'hooks';
import { BrandReadModelSimple, SearchBrandsRequest, useSearchBrands } from 'api/brand';
import Box from '@mui/material/Box/Box';
import { resolveS3FileUrl } from 'services';
import { Avatar } from '@mui/material';
import { sentenceToTitleCase } from 'utils';

/**
  * Current known issues:
  * MUI: The value provided to Autocomplete is invalid.
  * None of the options match with `{"id":"","name":"","brandPicId":""}`.
  * You can use the `isOptionEqualToValue` prop to customize the equality test.
  * 
  * Solution: Use freeSolo, but then we do not want random input into the brands field, so we leave it like that for now.
 */
export const BrandSearch = () => {

  // query state
  const [brandQuery, setBrandQuery] = useState<string>();

  // search result state
  const [searchResults, setSearchResults] = useState<BrandReadModelSimple[]>([]);
  
  // request hook
  const { data: searchData, isLoading, isError, error } = useSearchBrands({ q: brandQuery, pageSize: 20, pageIndex: 0 } as SearchBrandsRequest);

  // handle request success
  useEffect(() => { searchData && setSearchResults(searchData.data); }, [searchData]);

  // handle request error
  useHandleApiError(isError, error)

  // update brand query to trigger re-render so that react query makes new api call
  const handleSearchBrand = async (searchText: string) => {
    if (!searchText || searchText.length < 2) { return; }
    setBrandQuery(searchText);
  };

  const resolveBrandRowView = (props: any, option: BrandReadModelSimple) => 
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}  {...props}>
    {/* <img loading="lazy" width="40" src={resolveS3FileUrl(option.brandPicId)} alt="" /> {option.name} */}
    <Avatar variant='rounded' src={resolveS3FileUrl(option.brandPicId)} alt=""  sx={{ width: 32, height: 32, mr: 2 }} /> {sentenceToTitleCase(option.name)}
  </Box>
  
  return (
    <RHFAutocomplete
      name='brand'
      // freeSolo
      label='Brand Name'
      forcePopupIcon={false}
      options={searchResults}
      disableClearable
      filterOptions={(x) => x}
      placeholder='Search Brands'
      onInputChange={(_, value) => handleSearchBrand(value)}
      loading={!!brandQuery && brandQuery.length > 1 && isLoading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => resolveBrandRowView(props, option)}
      noOptionsText={ !!brandQuery && brandQuery.length > 1 ?  `Brand '${brandQuery || ''}' not found.` : 'Type to search brands...' }
      getOptionLabel={(option: string | BrandReadModelSimple) => typeof option === 'object' ? option.name : option}
    />
  )
};