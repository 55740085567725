import Typography from "@mui/material/Typography/Typography";
import { MessageList } from "stream-chat-react";

export const UNMessageList = () => {

    const customRenderText = (text: string|undefined) => {
        return <Typography variant="body2" >{ text }</Typography>
    };

    return ( <MessageList renderText={customRenderText} /> );
};