import { useParams } from 'react-router-dom';
import { useAppAuth, useAppLocation } from 'context';
import { useHandleApiError } from 'hooks';
import { DealTags, TermsAndConditions } from 'features/components';
import { DealsDetailTabs } from './detail-tabs';
import { DealDetailHelmet } from './details-share';
import { DeleteDealDialog } from './DeleteDealDialog';
import { Container, Grid, Stack } from '@mui/material';
import { DealDetailSkeleton } from 'components';
import { DealRequest, GetDealLinksRequest, useGetDeal, useGetDealLink } from 'api/deal';
import { DealDetailSnackBarBottom } from './DealDetailSnackBarBottom';
import { MainDealView } from './MainDealView';
import { useGetBrand } from 'api/brand';

export const DealDetailPage = () => {
    
    const { appUser } = useAppAuth();
    const { currentLocation } = useAppLocation();

    // get deal
    const { dealId } = useParams();
    const { data: currentDeal, isLoading: isLoadingDeal, isError: isGetError, error: getError } = useGetDeal({ dealId } as DealRequest, !!dealId);
    useHandleApiError(isGetError, getError, undefined);

    // get brand
    const { data: brandData, isLoading: isFetchingBrand, isError: getBrandIsError, error: getBrandError } = useGetBrand({ brandName: currentDeal?.brand || ''}, !!currentDeal);
    useHandleApiError(getBrandIsError, getBrandError);

    // get links
    const { data: dealLink, isLoading: isLoadingDealLink, isError: isGetDealLinkError, error: getDealLinkError, } = useGetDealLink(
        {
            dealId,
            countryCode: currentLocation?.countryCode,
        } as GetDealLinksRequest,
      (currentDeal !== undefined && currentLocation !== undefined)
    );
    useHandleApiError(isGetDealLinkError, getDealLinkError, undefined);

    const needCanBeDeleted = () => currentDeal && appUser && currentDeal.accountId === appUser.id && currentDeal.isLocked !== true;
    return (
        <Container>
        {
            !isLoadingDeal && currentDeal && dealLink && !isLoadingDealLink && !isFetchingBrand && brandData ? 

            // Deal Details 
            <>
                <DealDetailHelmet currentDeal={currentDeal} />
                
                <Grid mt={3} container spacing={3}>

                    <Grid item xs={12} md={7}>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12}>
                                <MainDealView currentDeal={currentDeal} dealLink={dealLink} dealsBrand={brandData} />
                                { appUser && <DealDetailSnackBarBottom currentDeal={currentDeal} /> }
                            </Grid>
                            {/* Delete Deal */}
                            { needCanBeDeleted() && <DeleteDealDialog dealId={currentDeal.id} /> }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} mb={2}>

                            <Stack mt={{ xs: 3, md: 8 }}>
                                <DealTags tags={currentDeal.tags} />
                            </Stack>
                            
                            <DealsDetailTabs currentDeal={currentDeal} />
                            
                            <TermsAndConditions />
                        </Stack>
                    </Grid>

                </Grid>
            </>

            :

            <DealDetailSkeleton  />
        }
        </Container>
    );
}
