import { DealsListCardProps, DealsCardContent } from './DealsCardContent';
import { resolveDealUrl } from 'utils';
import Link from '@mui/material/Link/Link';
import Card from '@mui/material/Card/Card';
import { UNImage } from 'components';
import { useTheme } from '@mui/material/styles';
import { resolveS3FileUrl } from 'services';

export const DealsListCard = ({ deal }: DealsListCardProps) => {
    const theme = useTheme();
    return (
        <Link key={deal.id} variant="body2" href={resolveDealUrl(deal.id)} target="_blank" sx={{ textDecoration: 'none' }} underline='none'>
            <Card sx={{ cursor: 'pointer' }}>
                <UNImage 
                    alt="need_image" 
                    src={resolveS3FileUrl(deal.dealPicId)}
                    ratio="1/1" 
                    sx={{ borderRadius: 0, border: `solid 1px ${theme.palette.background.neutral}`, }} 
                />
                <DealsCardContent deal={deal} />
            </Card>
        </Link>
    );
};
