import { useState, useRef, useEffect } from 'react';
import { notifyError } from 'components';
import PlaceHolderImage from 'assets/icons/upload/solid.svg';

import './DragAndDrop.css';
import Typography from '@mui/material/Typography/Typography';

export type CMDragAndDropType = {
    allowMultiple: boolean;
    handleSelectedFiles: (files: FileList) => void;
    imagePlaceholder?: string;
    maxAllowed?: number;
    children?: React.ReactNode;
    headerText?: string;
};

export const CMDragAndDrop = ({ 
    allowMultiple = false, 
    handleSelectedFiles, 
    imagePlaceholder = PlaceHolderImage,
    maxAllowed = 5,
    children,
    headerText = 'Upload image below',
}: CMDragAndDropType) => {

    const [dragActive, setDragActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    
    // ref : https://stackoverflow.com/a/70229222
    // to avoid using 'any' in the method signature, we need to allow MouseEventInit (for useEffect) and DragEvent (for typescript)
    const avoidEventDefaults = (event: React.DragEvent<HTMLDivElement> | MouseEventInit) => {
        event = event as React.DragEvent<HTMLDivElement>;

        event.preventDefault();
        event.stopPropagation();
    };
    
    const divRef = useRef<HTMLDivElement | null>(null);

    // prevent event defaults
    useEffect(() => {
        const currentRef = divRef.current;

        currentRef?.addEventListener('dragover', avoidEventDefaults);
        currentRef?.addEventListener('drop', handleDrop);

        return () => {
            currentRef?.removeEventListener('dragover', avoidEventDefaults);
            currentRef?.removeEventListener('drop', handleDrop);
        }
    });

    // handle drag
    const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
        avoidEventDefaults(event);
        
        if (event.type === "dragenter" || event.type === "dragover" ) {
            setDragActive(true);
        } else if (event.type === "dragleave" ) {
            setDragActive(false);
        }
    };

    // handle selected files
    const handleFiles = (files: FileList | null) => {
        
        setDragActive(false);

        if (files && files[0]) {
            setSelectedImage(URL.createObjectURL(files[0]));
            handleSelectedFiles(files);
        };
    };

    // handle drop to upload
    const handleDrop = (event: React.DragEvent<HTMLDivElement> | MouseEventInit) => {
        event = event as React.DragEvent<HTMLDivElement>;

        avoidEventDefaults(event);

        handleFiles(event.dataTransfer.files);
    };

    // handle click to upload
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleDivClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.nativeEvent.preventDefault();
        event.nativeEvent.stopPropagation();

        if (event.target.files && event.target.files.length > maxAllowed) {
            notifyError(`The maximum allowed file upload is ${maxAllowed}`);
            return;
        }

        handleFiles(event.target.files);
    };

    return (
        <>
            <div className='drag-and-drop'>
                <div 
                    onDrop={handleDrop}
                    onClick={handleDivClick} 
                    onDragEnter={handleDrag} 
                    onDragLeave={handleDrag}
                    ref={divRef} 
                    className={dragActive ? "drag-and-drop-container drag-active" : "drag-and-drop-container" }
                >
                    <input id="drag-and-drop-input" ref={inputRef} className="drag-and-drop-input" type='file' multiple={allowMultiple} onChange={handleFilesSelected} 
                />

                    <Typography variant='subtitle1' sx={{ color: 'var(--dim-color)' }} fontWeight='bold'> { headerText } </Typography>

                    {
                        children ? 
                        children :
                        <img
                            className='drag-and-drop-upload-image'
                            alt="click / drag and drop to upload"
                            src={selectedImage === null ? imagePlaceholder : selectedImage}
                            style={selectedImage === null ? {} : { objectFit:'cover', borderRadius: '50%' }}
                        />
                    }
                    
                    <Typography variant='caption' sx={{ color: 'var(--dim-color)' }} fontWeight='bold'> Drag and Drop / Click to Upload </Typography>
                </div>
            </div>
        </>
    );
};

type DargAndDropImageProp = {
    imageUrl: string;
    disableRounded?: boolean;
    disbaleObjectFitCover?: boolean;
};

export const DragAndDropImage = ({ imageUrl, disableRounded, disbaleObjectFitCover }: DargAndDropImageProp) => {
    return (
        <>
            <img 
                style={{ width: '200px', height: '200px', borderRadius: disableRounded ? '5%' : '50%', objectFit: disbaleObjectFitCover ? 'none' :'cover', margin: '11px 0', }}
                src={imageUrl}
                alt="click / drag and drop to upload"
            />
        </>
    )
};