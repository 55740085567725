import { useState } from "react";

import { 
    notifySuccess,
    RHFTextField,
    FormProvider,
    MyReCaptcha,
    notifyInfo,
    notifyError,
} from "components";
import { useHandleApiError } from "hooks";
import { AddBrandAffiliateRequest, BrandLinks, BrandReadModel, useAddBrandAffiliate, useDeleteBrandAffiliate } from "api/brand";
import { AddBrandAffiliateDetails, addBrandAffiliateValidationSchema, affilateLinkDefaultValues } from "./update-brand-details";
import { generateId, sentenceToTitleCase } from "utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Avatar, Divider, Typography } from "@mui/material";
import Card from '@mui/material/Card/Card';
import Stack from '@mui/material/Stack/Stack';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link/Link';
import { CountryType } from "services";
import { CountrySelect } from "features/components";
import { RemoveAffiliateLinkDialog } from "./RemoveAffiliateLinkDialog";

type BrandAffiliateLinksProps = {
    brand: BrandReadModel;
};

export const BrandAffiliateLinks = ({ brand }: BrandAffiliateLinksProps) => {

    // handle recaptcha
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const [currentBrand, setCurrentBrand] = useState<BrandReadModel>(brand);

    const successCallback = async (response: BrandReadModel) => {
        setCurrentBrand(response);
        notifySuccess("Brand successfully updated.");
    };

    // ======= Handle Add =====
    const { isLoading: isLoadingAdd, isError: isErrorAdd, error: errorAdd, mutate: addBrandAffiliate } = useAddBrandAffiliate(successCallback);
    useHandleApiError(isErrorAdd, errorAdd);
    const handleAddAffiliate = (values: AddBrandAffiliateRequest) => addBrandAffiliate(values);

    // ======= Handle Delete =====
    const { isLoading: isLoadingRemove, isError: isErrorRemove, error: errorRemove, mutate: deleteBrandAffiliate } = useDeleteBrandAffiliate(successCallback);
    useHandleApiError(isErrorRemove, errorRemove);
    const handleRemoveAffiliate = (affiliateId: string, apiKey: string, ticketId: string) => {
        if (currentBrand.brandLinks && currentBrand.brandLinks.length > 0) {
            deleteBrandAffiliate({ apiKey, ticketId, brandName: currentBrand.name, affiliateId });
        };
    };
    
    return (
        <BrandAffiliateLinksForm 
            brand={brand}

            recaptchaToken={recaptchaToken}
            setRecaptchaToken={setRecaptchaToken}

            isHandlingAdd={isLoadingAdd}
            handleAddAffiliate={handleAddAffiliate}

            isHandlingRemove={isLoadingRemove}
            handleRemoveAffiliate={handleRemoveAffiliate}
        />
    )
};

type BrandAffiliateLinksFormProps = {
    brand: BrandReadModel;
    
    isHandlingAdd?: boolean;
    handleAddAffiliate: (values: AddBrandAffiliateRequest) => void;

    isHandlingRemove?: boolean;
    handleRemoveAffiliate: (affiliateId: string, apiKey: string, ticketId: string) => void;

    recaptchaToken?: string | undefined | null;
    setRecaptchaToken: (val: string | null) => void;
};

export const BrandAffiliateLinksForm = ({
    brand,
    isHandlingAdd,
    handleAddAffiliate,
    isHandlingRemove,
    handleRemoveAffiliate,
    setRecaptchaToken, 
    recaptchaToken
}: BrandAffiliateLinksFormProps) => {

    const [brandCurrentAffiliates, setBrandCurrentAffiliates] = useState<BrandLinks[]>(brand?.brandLinks || []);

    const methods = useForm<AddBrandAffiliateDetails>({ resolver: yupResolver(addBrandAffiliateValidationSchema), defaultValues: affilateLinkDefaultValues, mode: 'all' });
    const { handleSubmit, formState: { isSubmitting, isValid, }, reset, setValue } = methods;

    const handleCountrySelect = (val: CountryType) => {
        setValue('countryCode', val.code);
        setValue('countryName', val.label);
    };
    
    const onSubmitAdd = (values: AddBrandAffiliateDetails) => {

        try {
            if (recaptchaToken) {
            
                const affilateComboExists =
                  brandCurrentAffiliates.filter(
                    (x) =>
                        x.name === values.name &&
                        x.link === values.link && 
                        x.countryCode === values.countryCode
                  ).length > 0;
        
                if (values.name && values.link && values.countryCode && values.countryName && !affilateComboExists) {
        
                    const affiliateId = generateId();
        
                    handleAddAffiliate({ ...values, id: affiliateId, brandName: brand.name, recaptchaToken });
        
                    // update locally
                    const updatedAffiliates = [
                      ...brandCurrentAffiliates,
                      {
                        name: values.name,
                        link: values.link,
                        countryCode: values.countryCode,
                        countryName: values.countryName,
                        id: affiliateId,
                      },
                    ];
                    setBrandCurrentAffiliates(updatedAffiliates);
        
                    reset();
                };
            } else {
                notifyInfo("Please complete the robot check.");
            }
        } catch (error) {
            notifyError("Something went wrong, please refresh your browser and try again.")
        }
    };

    const onSubmitRemove = (affiliate: BrandLinks, apiKey: string, ticketId: string) => {
      
        if (brandCurrentAffiliates.length < 1 || !affiliate) {
            return;
        };

        // make remote call
        handleRemoveAffiliate(affiliate.id, apiKey, ticketId);

        // update locally
        setBrandCurrentAffiliates(brandCurrentAffiliates.filter(x => x.id !== affiliate.id));

        reset();
    };

    return (
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitAdd)}>
          <Stack spacing={2}>
            <Typography
              gutterBottom
              variant="h5"
              textAlign="center"
              sx={{ color: "text.secondary" }}
            >
              Brand Affiliates
            </Typography>

            <RHFTextField name="name" label="Affiliate Name" />

            <RHFTextField name="link" label="Affiliate URL" />

            <CountrySelect onChange={handleCountrySelect} />

            <RHFTextField name="apiKey" label="Api Key" helperText='Api Key is required to add brand affiliate.'  />

            <Stack>
              <MyReCaptcha onRecaptchaChange={setRecaptchaToken} />
            </Stack>

            <Stack alignItems="flex-end" sx={{ mb: 3 }}>
                <LoadingButton
                    onClick={() => handleSubmit(onSubmitAdd)()}
                    size="large"
                    variant="contained"
                    loading={isHandlingAdd || isSubmitting}
                    type="button"
                    sx={{ textTransform: "capitalize" }}
                    disabled={ !isValid || !recaptchaToken }
                >
                    {!isHandlingAdd && <AddIcon />} Add
                </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>

        <Divider sx={{ pb: 3, pt: 0 }} />

        <Stack alignItems="flex-end" spacing={2} sx={{ p: 3 }}>
          {brandCurrentAffiliates.length > 0 ? (
            brandCurrentAffiliates.map((affiliates, index) => {
              return (
                <Stack
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                  spacing={1}
                  key={index}
                  direction="row"
                  sx={{ wordBreak: "break-all" }}
                >
                  <Stack direction="column" spacing={1}>

                    <Stack direction='row'  spacing={1}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Name :
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
                            { sentenceToTitleCase(affiliates.name) }
                        </Typography>
                    </Stack>

                    <Stack direction='row' spacing={1}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Link :
                        </Typography>

                        <Link href={affiliates.link} variant="body1" color='primary' underline="always" >
                        <Typography> Affiliate Link </Typography>
                        </Link>
                    </Stack>

                    <Stack direction='row' spacing={1}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Country :
                        </Typography>
                        <Stack direction='row'>
                            <Avatar sx={{ width: '20px', height: '20px', cursor: 'pointer', mr: 1 }} src={`https://flagcdn.com/w40/${affiliates.countryCode.toLowerCase()}.png`}/>
                            <Typography variant="body2"> {affiliates.countryName} </Typography>
                        </Stack>
                    </Stack>

                  </Stack>

                  <RemoveAffiliateLinkDialog affiliate={affiliates} handleRemoveAffiliate={onSubmitRemove} />
                </Stack>
              );
            })
          ) : (
            <Typography
              variant="body2"
              sx={{ color: "text.secondary" }}
              gutterBottom
              alignSelf="center"
            >
              This brand has no affiliate links. Add with form above.
            </Typography>
          )}
        </Stack>
      </Card>
    );
};