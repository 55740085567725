import { createContext, useContext, useEffect, useState } from 'react';
import { LocationCity, LocationData, resolveLocationData } from "services";

interface AppLocationContextInterface {

    /**
     * current location is the source of truth and is not editable by the app user.
     * it is derived by calling out to our location api via the location service.
     * This tells us for certain where the user is
     */
    currentLocation: LocationData;

    /**
     * user selected location is over rideable by the app user.
     * it is ideal for things like searching a different locaiton than where the user is located
     */
    userSelectedLocation: LocationCity;
    updateUserSelectedLocation: (data: LocationCity) => void;
};

const AppLocationContext = createContext<AppLocationContextInterface | undefined>(undefined);

const useAppLocation = (): AppLocationContextInterface => {
    const appLocationContext = useContext(AppLocationContext);

    if (appLocationContext === undefined) {
        throw new Error ("invalid app location context.");
    };

    return appLocationContext;
};

const useProvideAppLocation = (): AppLocationContextInterface => {

    const [locationData, setLocationData] = useState<LocationData>();
    const [userSelectedLocation, setUserSelectedLocation] = useState<LocationCity>();

    const handleUserLocationUpdate = (cityData: LocationCity) => {
        setUserSelectedLocation({
            countryName: cityData.countryName,
            countryCode: cityData.countryCode,
            regionName: cityData.regionName,
            regionCode: cityData.regionCode,
            city: cityData.city,
        });
    };

    // fetch current user location for first load
    useEffect(() => {
        const fetchLocationData = async () => { 
            let locData = await resolveLocationData(); 
            setLocationData(locData);
            handleUserLocationUpdate(locData as LocationCity);
        };
        fetchLocationData();
    }, []);

    return {
        userSelectedLocation: userSelectedLocation,
        currentLocation: locationData,
        updateUserSelectedLocation: handleUserLocationUpdate,
    } as AppLocationContextInterface;
};

const AppLocationProvider = ({children}: {children: React.ReactNode}) => {
    const appLocationContextValue = useProvideAppLocation();
    
    return (
        <>
            <AppLocationContext.Provider value={appLocationContextValue}>
                {children}
            </AppLocationContext.Provider>
        </>
    );
};

export { AppLocationProvider, useAppLocation };