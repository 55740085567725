import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled as MuiStyled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';

export const StyledFormControl = MuiStyled(FormControl)({
    marginBottom: "11px",
    marginRight: "4px",

    "& .MuiInputBase-root": {
        borderRadius: "8px",
        textAlign: "left"
    },

    "& .MuiInputLabel-formControl": {
        textAlign: "left"
    },
});

type UNDropDownMenuType = {
    displayText: string;
    dropDownOptions: string[]; 
    handleSelectedOption: (selectedValue: string) => void;
    style?: {};
    error?: boolean;
    helperText?: string | false | undefined;
}

export const UNDropDownMenu = ({ 
    style = {}, 
    displayText = "select", 
    dropDownOptions = [ "1", "2", "3" ], 
    handleSelectedOption = (selectedValue: string) => {},
    error = false,
    helperText,
}: UNDropDownMenuType) => {
    const [selectedValue, setSelectedValue] = React.useState('');
  
    const handleChange = (event: SelectChangeEvent) => onChange(event.target.value);
    const handleBlur = (event: React.FocusEvent<any>) => onChange(event && event.target && event.target.value);

    const onChange = (value: any) => {
        if (value) {
            setSelectedValue(value);
            handleSelectedOption(value);
        }
    };
  
    return (
        <>
            <StyledFormControl style={{ ...style, width: '100%', maxWidth: '100%' }} variant="filled" error={error}>
                <InputLabel error={error}>{displayText}</InputLabel>
                <Select 
                    sx={{ textTransform: 'capitalize' }}
                    id='drop-down-select'
                    value={selectedValue} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disableUnderline={true} 
                    renderValue={(selected) => {
                        if (selected.length === 0) return <em>{displayText}</em>;
                        return selected;
                    }}
                >
                    <MenuItem disabled selected value=""> <em>{displayText}</em> </MenuItem>
                    {
                        dropDownOptions.map((option, index) => (
                            <MenuItem style={{ textTransform: 'capitalize' }}key={index} value={option}>{`${option}`}</MenuItem>
                        ))
                    }
                </Select>
                { error && helperText ? <FormHelperText> { helperText } </FormHelperText> : null }
            </StyledFormControl>
        </>
    );
};