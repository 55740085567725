import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { object, string } from "yup";
import { FormProvider, RHFTextField, notifyWarning } from "components";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import Button from "@mui/material/Button/Button";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Stack from "@mui/material/Stack/Stack";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Dialog from "@mui/material/Dialog/Dialog";
import RemoveIcon from '@mui/icons-material/Remove';
import Container from "@mui/material/Container/Container";
import { BrandLinks } from "api/brand";

interface RemoveAffiliateLinkDialogProps {
    affiliate: BrandLinks;
    handleRemoveAffiliate: (affiliate: BrandLinks, apiKey: string, ticketId: string) => void;
};

export const RemoveAffiliateLinkDialog = ({ affiliate, handleRemoveAffiliate }: RemoveAffiliateLinkDialogProps) => {

    const [openCloseNeed, setOpenCloseNeed] = useState(false);
    const handleOpen = () => setOpenCloseNeed(true);
    const handleClose = () => setOpenCloseNeed(false);

    // form data
    const methods = useForm<{apiKey: string; ticketId: string; }>({
        resolver: yupResolver(
            object().shape({
                apiKey: string().required(),
                ticketId: string().required(),
            })
        ),
        defaultValues: { apiKey: "", ticketId: "" },
        mode: "all",
    });
    const { reset, handleSubmit, formState: { isValid, isSubmitting }} = methods;
    
    // handle cancel
    const onCancel = () => {
        handleClose();
        reset();
    };

    const onSubmit = (data: {apiKey: string; ticketId: string; }) => {
        
        if (!isValid) {
            notifyWarning("Please add");
            return;
        };

        handleRemoveAffiliate(affiliate, data.apiKey, data.ticketId);

        onCancel();
    };

    return (
        <>
            <Button onClick={handleOpen} color='error' variant='text' size="small">
                <RemoveIcon sx={{ color: 'text.secondary' }} fontSize="small" />
                Remove
            </Button>
            <Dialog open={openCloseNeed} onClose={handleClose}>
                <Container maxWidth="md">
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle> Remove Affiliate Link </DialogTitle>

                        <DialogContent>
                            <Stack spacing={3} padding={1}>
                                <RHFTextField name="apiKey" label="Api Key" fullWidth />

                                <RHFTextField name="ticketId" label="Ticket Id" />
                            </Stack>
                        </DialogContent>

                        <DialogActions>
                            <LoadingButton type="submit" color="error" variant="contained" loading={isSubmitting}>
                                Remove
                            </LoadingButton>

                            <Button color="inherit" variant="outlined" onClick={onCancel}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </FormProvider>
                </Container>
            </Dialog>
        </>
    )
};