import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MyToastContainer } from 'components';
import { reportWebVitalMetrics } from './stats';
import { AppProviders } from 'context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
      <MyToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppProviders>
  </React.StrictMode>
);

reportWebVitalMetrics();
