import Button from "@mui/material/Button/Button";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { appConfig } from "config";
import Modal from "@mui/material/Modal/Modal";
import Box from "@mui/material/Box/Box";
import { useState } from "react";
import Stack from "@mui/material/Stack/Stack";
import logo from 'assets/logo/logo.png';
import { AppPages } from "routes";
import { Link } from "react-router-dom";
import { TermsAndConditions } from "features/components";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export const AuthButton = () => {
    
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
        <div>
            <Button size='large' variant='contained' onClick={handleOpen}>SignUp / LogIn</Button>

            <Modal open={open} onClose={handleClose} >
                <Box sx={style}>
                    <Stack direction='column' spacing={2}>
                        <Link to={AppPages.Home}>
                            <img src={logo} alt="the_plug_logo" hidden/>
                        </Link>
                        <AuthWithGoogle />
                        <AuthWithFacebook />
                        <TermsAndConditions callback={handleClose} />
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}

export const AuthWithFacebook = () => {
    return (
        <Button 
            size='large' 
            type='button' 
            variant='outlined' 
            startIcon={<FacebookIcon />}
            style={{
              backgroundColor: "#3b5998",
              color: "#fff",
            }}
            sx={{ bgcolor: 'blue', color: 'white' }}
            onClick={() => window.location.href=`${appConfig.plugsDealApi.baseUrl}/account/facebook-login`}
        >
            Continue with Facebook
        </Button>
    );
};

export const AuthWithGoogle = () => {
    return (
        <Button 
            size='large' 
            type='button' 
            variant='outlined' 
            startIcon={<GoogleIcon />}
            style={{
              backgroundColor: "#DB4437",
              color: "#fff",
            }}
            sx={{ bgcolor: 'blue', color: 'white' }}
            onClick={() => window.location.href=`${appConfig.plugsDealApi.baseUrl}/account/google-login`}
        >
            Continue with Google
        </Button>
    );
};