import Card from "@mui/material/Card/Card";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { AppUser } from "context";

type AccountToDosProps = {
    appUser: AppUser;
};

export const AccountToDos = ({ appUser }: AccountToDosProps) => {
    return (
        <>
            <Typography textAlign="center" variant='body1' sx={{ color: 'text.secondary' }} mx={3} gutterBottom> 
                Please complete the below to continue using The Plug's Deals.
            </Typography>

            <Card sx={{ p: 3 }}>
                <Stack alignItems="flex-start">
                    <List sx={{ listStyleType: 'disc', pl: 3 }} dense>
                        { appUser.toDos.map((toDo, index) => <ListItem key={index} sx={{ display: 'list-item' }}> <ListItemText primary={toDo} /> </ListItem>) }
                    </List>
                </Stack>
            </Card>
        </>
    )
};