import { pathToUrl } from 'utils';
import { accountRoutes } from './routes';
import { ApiMessageResponse } from '../interface';
import { usePost, usePut, useFetch, useDelete } from '../apiClient';
import { 
    GetAccountResponse,
    GetAccountRequest,
    UpdateAccountRequest,
    CreateQueryRequest,
    GetAccountByUsernameRequest,
    GetAccountChatTokenRequest,
    GetAccountChatTokenResponse,
} from './interface';

export * from './interface';

// mutations and actions

export const useUpdateAccount = (successCallback: (resp: GetAccountResponse) => void) => 
    usePut<UpdateAccountRequest, GetAccountResponse>(pathToUrl(accountRoutes.update), undefined, successCallback);

export const useCreateQuery = (successCallback: (resp: ApiMessageResponse) => void) => 
    usePost<CreateQueryRequest, ApiMessageResponse>(pathToUrl(accountRoutes.query), undefined, successCallback);

export const useDeleteAccount = (successCallback: (resp: ApiMessageResponse) => void) => 
    useDelete<{ email: string; }, ApiMessageResponse>(pathToUrl(accountRoutes.delete), undefined, successCallback);


// queries

export const useGetAccount = (req: GetAccountRequest, isEnabled?: boolean) => 
    useFetch<GetAccountResponse>(req.id ? pathToUrl(accountRoutes.get) : null, req, isEnabled);

export const useGetAccountByUsername = (req: GetAccountByUsernameRequest) => 
    useFetch<GetAccountResponse>(req.username ? pathToUrl(accountRoutes.username) : null, req);

export const useGetAccountChatToken = (req: GetAccountChatTokenRequest, isEnabled?: boolean) => 
    useFetch<GetAccountChatTokenResponse>(req.id ? pathToUrl(accountRoutes.chatToken) : null, req, isEnabled);