export interface RequestLocationData {
    countryCode: string;
    countryName: string;
    regionCode: string;
    regionName: string;
    city: string;
};

export interface GetAccountDealsRequest {
    accountId: string;

    orderBy: string;
    type: FilterDealTypeBy;

    pageSize: number;
    pageIndex: number;
};

export interface CreateDealRequest {
    dealPicId?: string;
    type: string;
    description: string;
    dealCode?: string;
    isHidden: boolean;
    recaptchaToken: string;
    startDate: Date;
    endDate: Date;
    brand: string;
    tags: string[];
    location: RequestLocationData;
    apiKey: string;
    ticketId: string;
};

export interface DealRequest {
    dealId: string;
};

export interface DeleteDealRequest {
    dealId: string;
    apiKey: string;
};

export interface GetDealLinksRequest {
    dealId: string;
    countryCode: string;
};

export interface GetDealLinkResponse {
    brandName: string;
    website: string;
};


export enum DealTypes {
    OnlineCode = 'online_code',
    InstoreCoupon = 'in_store_coupon',
    CashBack = 'cash_back',
    OnlineSaleTip = 'online_sale_tip'
};

export enum FilterDealTypeBy {
    All = 'all',
    OnlineCode = 'online_code',
    CashBack = 'cash_back',
    InstoreCoupon = 'in_store_coupon',
    OnlineSaleTip = 'online_sale_tip'
};

export enum FilterDealsBy {
    DealsByAccount = 'deals_by_account',
    DealsByTag = 'deals_by_tag',
    DealsByCategory = 'deals_by_category',
    DealsByBrand = 'deals_by_brand'
};

export enum OrderDealsBy {
    LATEST = 'latest',
    POPULAR = 'popular',
    OLDEST = 'oldest',
    END_DATE = 'end_date',
};

export interface QueryDealsRequest {

    orderBy: OrderDealsBy;

    brand?: string;
    tags?: string[];
    type?: FilterDealTypeBy;
    
    pageSize: number;
    pageIndex: number;

    country?: string;
};

export interface SearchDealTagsRequest {
    q: string;
    pageSize: number;
    pageIndex: number;
};

export interface SearchTagRequest {
    q: string;
    pageSize: number;
    pageIndex: number;
};

export interface LocationDataReadModel {
    countryCode: string;
    countryName: string;
    regionCode: string;
    regionName: string;
    city: string;
};

export interface DealReadModel {
    
    id: string;
    title: string;
    accountId: string;
    type: string;
    website: string;
    description: string;
    brand: string;
    category: string;
    tags: string[];
    startDate: Date;
    isHidden: boolean;
    isClosed: boolean;
    dealCode?: string;
    isOfficial: boolean;
    dealPicId: string;
    createdOn: Date;
    location: LocationDataReadModel;

    // mutable
    endDate: Date;
    views: number;
    upvotes: number;
    shares: number;
    bookmarks: number;
    isLocked: boolean;
    
    updatedOn: Date;

    // computed properties
    popularity?: number;
};

export interface DealActivityReadModel {

    dealId: string;
    accountId: string;

    hasViewedDeal: boolean;
    hasUpvotedDeal: boolean;
    hasBookmarkedDeal: boolean;
};

export enum DealShareTypeEnum {
    COPY = 'copy',
    EMAIL = 'email',
    TWITTER = 'twitter',
    WHATSAPP = 'whatsapp',
    FACEBOOK = 'facebook',
};

export interface DealShareRequest {
    dealId: string;
    shareType: string;
};
