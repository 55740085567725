import { addMonths } from 'date-fns';
import { TAGS_DESCRIPTION, TAGS_REGEX } from 'services';
import { CreateDealRequest, DealTypes } from "api/deal";
import { array, boolean, date, object, SchemaOf, string } from 'yup';
import { BrandReadModelSimple } from 'api/brand';

export interface CreateDealDetails extends Omit<CreateDealRequest, 'recaptchaToken' | 'location' | 'brand'  > {
    // tags: TagReadModel[];
    brand: BrandReadModelSimple;
};

const brandValidationSchema: SchemaOf<BrandReadModelSimple> = object().shape({
    id: string().required('required'),
    name: string().required('required'),
    brandPicId: string().required('required'),
}, []);
    
export const createDealValidationSchema: SchemaOf<CreateDealDetails> = object().shape({

    brand: brandValidationSchema.required('who brand is this deal for?'),

    type: string().oneOf(Object.values(DealTypes)).required('What type of Deal is this?'),

    dealPicId: string().required(),

    description: string().min(10, 'Minimum 10 characters.').max(500, 'Maximum 500 characters').required('required'),
    dealCode: string()
        .optional()
        .when("type", {
            is: (type: DealTypes) => type === DealTypes.OnlineCode,
            then: string().required('required')
        }),

    startDate: date().default(() => new Date()).required('When does the deal start?'),
    endDate: date().when("startDate", (startDate, schema) => startDate && schema.min(startDate)).required('When does the deal end?'),

    tags: array().of(string().matches(new RegExp(TAGS_REGEX), TAGS_DESCRIPTION).required())
        .min(1, 'Tags make it easier for Deals to be found. Add at least 1.')
        .max(9, 'A maximum of 9 tags allowed.')
        .required('Tags make it easier for Deals to be found. Add at least 1.'),

    isHidden: boolean().required('Only users with a link can view the deal.'),

    apiKey: string().required(),

    ticketId: string().required()
}, []);

export const createDealDefaultValues = (): CreateDealDetails => {

    const now = new Date();

    return {
        type: DealTypes.OnlineCode,
        brand: { 
            id: '',
            name: '',
            brandPicId: ''
        },

        dealPicId: '',

        description: '',
        dealCode: '',

        startDate: now,
        endDate: addMonths(now, 3),

        tags: [],

        isHidden: false,

        apiKey: '',
        ticketId: ''
    };
};