import { thirdPartyHttpClient } from "api/httpClient";
import { appConfig } from "config";

const locationApi = `https://api.ipdata.co?api-key=${appConfig.ipData.apiKey}`;

export interface LocationData {
    ipAddress: string;
    continentCode: string;
    continentName: string;
    countryCode: string;
    countryName: string;
    regionCode: string;
    regionName: string;
    city: string;
    latitude: number;
    longitude: number;
    currencySymbol: string;
    currencyCode: string;
    currencyName: string;
};

export interface LocationCity {
    countryName: string;
    countryCode: string;
    regionName: string;
    regionCode: string;
    city: string;
}

const retrieveLocationData = async (): Promise<LocationData> => {

    let location: LocationData = {} as LocationData;
    
    try {
        const response = await thirdPartyHttpClient.get(locationApi);

        if (response && response.data) {
            location.ipAddress = response.data.ip;
            location.continentCode = response.data.continent_code;
            location.continentName = response.data.continent_name;
            location.countryCode = response.data.country_code;
            location.countryName = response.data.country_name;
            location.regionCode = response.data.region_code;
            location.regionName = response.data.region;
            location.city = response.data.city;
            location.latitude = response.data.latitude;
            location.longitude = response.data.longitude;
            location.currencySymbol = response.data.currency.symbol;
            location.currencyCode = response.data.currency.code;
            location.currencyName = response.data.currency.name;

            return location;
        };

    } catch (error) {
        throw new Error("We can not determine where you are. Please refresh your browser and try again.");
    };

    return location;
};

export const resolveLocationData = async (): Promise<LocationData> => {
    return new Promise<LocationData>((resolve, reject) => {
        
        retrieveLocationData()
        .then((data: LocationData) => {
            return resolve(data);
        })
        .catch((error) => {
            reject(error);
        })
    });
};