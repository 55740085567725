import { appConfig } from 'config';
import ReCAPTCHA from 'react-google-recaptcha';
 
export const MyReCaptcha = ({ onRecaptchaChange = (token: string | null) => {} }) => {
    return (
        <>
            <ReCAPTCHA
                data-testid="recaptcha-test-id"
                onChange={onRecaptchaChange}
                sitekey={appConfig.reCaptcha.siteKey} 
                style={{ justifyContent: 'center', display: 'flex', margin: '0 12px 12px 12px' }}
            />
        </>
    )
};