
import { useState } from "react";
import { AppUser, ImageUploadProvider, useAppAuth } from 'context';

import { UNHelp } from 'features/components';
import { AccountToDos } from './AccountToDo';
import { AccountProfile } from "./AccountProfile";
import { AccountSecurity } from "./AccountSecurity";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HelpIcon from '@mui/icons-material/Help';
import Badge from "@mui/material/Badge/Badge";
import Container from "@mui/material/Container/Container";
import Box from "@mui/material/Box/Box";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Iconify, SettingsSkeleton } from "components";
import { ProfileBookmarkedDeals } from "./BookmarkDeals";

interface DashboardInterface {
    key: string;
    label: string;
    icon: React.ReactElement;
    component: React.ReactNode;
};

enum AccountDashboardTabs {
    Profile = 'Profile',
    Security = 'Security',
    Bookmark = 'Bookmarks',
    Help = 'Help',
    ToDo = 'To Do'
};

const resolveAccountDashboardTabs = (appUser: AppUser): DashboardInterface[] => {
    
    const tabs: DashboardInterface[] = [
        {
            key: AccountDashboardTabs.Profile.toLowerCase(),
            label: AccountDashboardTabs.Profile,
            component: <AccountProfile appUser={appUser} />,
            icon: <ManageAccountsIcon />
        },
        {
            key: AccountDashboardTabs.Bookmark.toLowerCase(),
            label: AccountDashboardTabs.Bookmark,
            icon: <Iconify fontSize='small' icon='eva:bookmark-fill' />,
            component: <ProfileBookmarkedDeals appUser={appUser} />,
        },
        {
            key: AccountDashboardTabs.Help.toLowerCase(),
            label: AccountDashboardTabs.Help,
            component: <UNHelp />,
            icon: <HelpIcon />
        },
        {
            key: AccountDashboardTabs.Security.toLowerCase(),
            label: AccountDashboardTabs.Security,
            component: <AccountSecurity />,
            icon: <VpnKeyIcon />
        }
    ];

    if (appUser && appUser.profileComplete !== true && appUser.toDos?.length > 0) {
        tabs.push({
            key: AccountDashboardTabs.ToDo.toLowerCase(),
            label: AccountDashboardTabs.ToDo,
            component: <AccountToDos appUser={appUser} />,
            icon: <Badge badgeContent={appUser.toDos.length} color="error" anchorOrigin={{ vertical: 'top', horizontal: 'right', }}> <PriorityHighIcon /> </Badge>
        });
    };

    return tabs;
};

export const AccountSettings = () => {
    
    const { appUser, isLoading } = useAppAuth();

    const [currentTab, setCurrentTab] = useState(AccountDashboardTabs.Profile.toLowerCase());

    return (
        <Container style={{ margin: '20px' }} maxWidth='lg'>
            <ImageUploadProvider>
                <Tabs 
                    value={currentTab}
                    variant="scrollable"
                    orientation="horizontal"
                    onChange={(event, newValue) => setCurrentTab(newValue)} scrollButtons="auto"
                >
                    {
                        resolveAccountDashboardTabs(appUser).map((tab) => (
                            <Tab key={tab.key} label={tab.label} icon={tab.icon} value={tab.key} iconPosition='start' />
                        ))
                    }
                </Tabs>

                {
                    isLoading || !appUser ? 

                    <SettingsSkeleton />

                    :

                    resolveAccountDashboardTabs(appUser).map((tab) => 
                        tab.key === currentTab && 
                        (
                            <Box key={tab.key} sx={{ mt: 5 }}>
                                {tab.component}
                            </Box>
                        )
                    )
                }
            </ImageUploadProvider>
        </Container>
    );
};