export const snakeToTitle = (snakeStr: string): string => {
  if (!snakeStr) return '';
  
  return snakeStr
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const sentenceToTitleCase = (input: string): string => {
  return input.toLowerCase().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
};