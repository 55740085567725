import { Helmet } from "react-helmet-async";
import { resolveS3FileUrl } from "services";
import { DealReadModel } from "api/deal";
import { sentenceToTitleCase } from "utils";

type DealDetailsShareProps = {
    currentDeal: DealReadModel;
};

export const DealDetailHelmet = ({ currentDeal }: DealDetailsShareProps) => {

    const hashtag = "#ThePlugsDeal"
    const currentUrl = window.location.href;
    const shareImage = resolveS3FileUrl(currentDeal.dealPicId);

    return (
        <Helmet>
            <title> { sentenceToTitleCase(currentDeal.brand) } </title>
            <meta property="title" content={ currentDeal.title } />
            <meta property="og:title" content={ currentDeal.title } />

            <meta property="og:quote" content={ currentDeal.title } />

            <meta property="og:locale" content="en_US" />

            <meta property="type" content="website" />
            <meta property="og:type" content="website" />

            <meta property="url" content={currentUrl} />
            <meta property="og:url" content={currentUrl} />

            <meta property="quote" content={ currentDeal.title } />

            <meta name="description" content={ currentDeal.title } />
            <meta property="og:description" content={ currentDeal.description } />

            <meta property="image" content={ shareImage } />
            <meta property="og:image" content={ shareImage } />
            <meta content="image/*" property="og:image:type" />


            <meta property="og:hashtag" content={ hashtag } />

            <meta property="og:site_name" content="The Plug" />

            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        </Helmet>
    )
};