import CircularProgress from "@mui/material/CircularProgress";

export const FullPageSpinner = () => {
    return (
        <div style={{
            fontSize: '4em',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <CircularProgress  color="primary"/>
        </div>
    )
};

export const LoadingSpinner = () => (<CircularProgress style={{ margin: '32px' }} color="secondary" />);