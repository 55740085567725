import { appConfig } from 'config';
import { PutObjectCommand, PutObjectCommandOutput, S3Client, S3ClientConfig } from '@aws-sdk/client-s3';

const S3_CONFIG: S3ClientConfig = {
    region: appConfig.aws.region,
    credentials: {
        accessKeyId: appConfig.aws.accessKeyId,
        secretAccessKey: appConfig.aws.secretAccessKey,
    },
};

const S3_CLIENT = new S3Client(S3_CONFIG);

export interface ImageUploadPayload{
    bucketName: string;
    fileId: string;
    fileToUpload: File;
}
export const uploadFileToS3 = async ({ bucketName, fileId, fileToUpload }: ImageUploadPayload): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        
        const putObjectCommand = new PutObjectCommand({
            Key: fileId,
            Bucket: bucketName,
            Body: fileToUpload,
        });

        S3_CLIENT
        .send(putObjectCommand)
        .then((data: PutObjectCommandOutput) => {
            return resolve(true);
        })
        .catch((error) => {
            console.error(error)
            reject(error);
        })
    });
};

export const uploadFilesToS3 = async (payload: ImageUploadPayload[]): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        var putObjectPromises = [];

        for (var i = 0; i < payload.length; i++) {
            putObjectPromises.push(uploadFileToS3(payload[i]));
        };

        Promise
            .all(putObjectPromises)
            .then((response) => {
                resolve(true);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const resolveS3FileUrl = (fileId: string): string => `${appConfig.aws.awsCFAssetsDistributionDomain}${fileId}`;