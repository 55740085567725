import { AppUser } from 'context';
import { useForm } from 'react-hook-form';
import { useHandleApiError } from 'hooks';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card/Card';
import Stack from '@mui/material/Stack/Stack';
import Container from '@mui/material/Container/Container';
import { DealsListSkeleton, FormProvider } from 'components';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { DealsListCard, DealsListGrid, SelectSort } from 'features/components';
import { DealReadModel, FilterDealTypeBy, OrderDealsBy, useGetDealsByAccountBookmarks } from 'api/deal';


type ProfileHomeProps = {
  appUser: AppUser;
};

export const ProfileBookmarkedDeals = ({ appUser }: ProfileHomeProps) => {
    
    const methods = useForm({ defaultValues: { type: FilterDealTypeBy.All, orderBy: OrderDealsBy.LATEST } });
    const { watch } = methods;
    const values = watch();

    const [pageIndex, setPageIndex] = useState<number>(0);

    const { data: accountBMDeals, isLoading: isLoadingAccountBMDeals, isError: isAccountBMDealsError, error: accountBMDealsError, } = useGetDealsByAccountBookmarks({
        accountId: appUser.id,
        pageIndex,
        pageSize: 10,
        type: values.type,
        orderBy: values.orderBy
    });
    useHandleApiError(isAccountBMDealsError, accountBMDealsError);

    const [totalProfileDeals, setTotalProfileDeals] = useState<DealReadModel[]>([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { accountBMDeals && setTotalProfileDeals([...totalProfileDeals, ...accountBMDeals.data]); }, [accountBMDeals]);
    
    return (
        <Container maxWidth='lg'>
            <FormProvider methods={methods}>
                <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }} alignItems='center' justifyContent='flex-end'>
                    <SelectSort name='type' label='Type' options={Object.values(FilterDealTypeBy)} />
                    <SelectSort name='orderBy' label='Order By' options={Object.values(OrderDealsBy)} />
                </Stack>
            </FormProvider>

            <DealsListGrid>
                {
                    !isLoadingAccountBMDeals && totalProfileDeals ? 
                    <>
                        {
                            totalProfileDeals.length > 0 ?
                            totalProfileDeals.map((need, index) => ( <DealsListCard deal={need} key={index} /> ))
                            :
                            <Card sx={{ p: 3, width: '100%' }}>
                                You have no bookmarked deals.
                            </Card>
                        }
                    </>
                    :
                    <DealsListSkeleton />
                }
            </DealsListGrid>

            {
                accountBMDeals && accountBMDeals.hasNextPage &&
                <Stack m={5} direction="row" alignItems="center" justifyContent="center">
                    <LoadingButton loading={isLoadingAccountBMDeals} variant="outlined" size="large" onClick={() => setPageIndex(pageIndex + 1)}>
                        Load More
                    </LoadingButton>
                </Stack>
            }
        </Container>
    );
}
