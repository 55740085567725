import { Button, Container, ListItem, Stack, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import List from '@mui/material/List/List';
import { TermsAndConditionsCopy } from "./TermsAndConditionsCopy";
import { useNavigate } from "react-router-dom";
import { AppPages } from "routes";

export const TermsAndConditions = ({ callback }: { callback?: VoidFunction } ) => {
    
    const navigate = useNavigate();
    const goToTsNCs = () => {
        navigate(AppPages.TermsAndCondition);
        callback && callback();
    };

    return (
        <Button onClick={goToTsNCs}>
            <Typography>Terms and Conditions</Typography>
        </Button>
    )
}

export const TermsOfService = () => {
    return (
        <>
            <Helmet>
                <title>The Plug | T's and C's</title>
            </Helmet>

            <Container maxWidth='lg'>
                <Typography textAlign='center' variant="h2" mt={2}>
                    Terms and Conditions for The Plug
                </Typography>

                <Typography color="text.secondary" variant="body1" mt={0} mb={2}>
                    Effective from 1st November 2023
                </Typography>
                <>
                {
                    TermsAndConditionsCopy.map((term, index) => {
                        return (
                            <Stack sx={{ pb: 2 }} key={term.headingNumber+"top-heading"+index}>
                                <Typography key={term.headingNumber+"heading"+index} variant="h4">
                                    {term.headingNumber} {term.heading}
                                </Typography>

                                <List sx={{ listStyleType: 'disc', pl: 3 }} dense>
                                    { term.body.map((subterm, index) => 
                                        <ListItem key={subterm.subHeadingNumber+"subheading"+index} > 
                                            <Typography color="text.secondary" variant="body1">
                                                {subterm.subHeadingNumber} {subterm.subHeadingBody}
                                            </Typography> 
                                        </ListItem>) 
                                    }
                                </List>
                            </Stack>
                        )
                    })
                }
                </>

                {/* <Typography textAlign='center' variant="caption" my={2}>
                    © The Plug, Inc. 
                    <br />
                    All Rights Reserved.
                </Typography> */}
            </Container>
            
        </>
    )
}