export type TermsAndConditionsType = {
    heading: string;
    headingNumber: string;
    body: {
        subHeadingNumber: string;
        subHeadingBody: string;
    }[]
};

export const TermsAndConditionsCopy: TermsAndConditionsType[] = [
    {
        heading: 'Acceptance of Terms',
        headingNumber: "1",
        body: [{
            subHeadingNumber: "1.1",
            subHeadingBody: `These terms and conditions ("Terms") govern your use of The Plug website and services (the "Platform"). By accessing or using the Platform, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please refrain from using the Platform.`
        }]
    },
    {
        heading: 'Eligibility',
        headingNumber: "2",
        body: [{
            subHeadingNumber: "2.1",
            subHeadingBody: `You must be at least 18 years old to use the Platform or, if you are under 18, you may use the Platform with the consent of a parent or legal guardian.`
        }]
    },
    {
        heading: 'User Accounts',
        headingNumber: "3",
        body: [
            {
                subHeadingNumber: "3.1",
                subHeadingBody: `You may be required to create a user account to access certain features of the Platform. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.`
            },
            {
                subHeadingNumber: "3.2",
                subHeadingBody: `You agree to provide accurate, current, and complete information during the registration process and to update your information as necessary to maintain its accuracy.`
            }
        ]
    },
    {
        heading: 'Content and Submissions',
        headingNumber: "4",
        body: [
            {
                subHeadingNumber: "4.1",
                subHeadingBody: `You may have the ability to post, submit, or otherwise make available content on the Platform, including deals, reviews, comments, and other user-generated content. You are solely responsible for the content you post, and you agree not to post any content that violates these Terms.`
            },
            {
                subHeadingNumber: "4.2",
                subHeadingBody: `By posting content on the Platform, you grant The Plug a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content.`
            }
        ]
    },
    {
        heading: 'Deal Listings',
        headingNumber: "5",
        body: [
            {
                subHeadingNumber: "5.1",
                subHeadingBody: `The Plug is a platform that displays deals and offers from third-party merchants. We do not endorse, warrant, or guarantee the accuracy or reliability of the information provided by merchants or the quality of their products or services.`
            },
            {
                subHeadingNumber: "5.2",
                subHeadingBody: `The availability, price, and terms of deals are subject to change without notice, and The Plug is not responsible for any discrepancies in deals or errors in deal listings.`
            }
        ]
    },
    {
        heading: 'Privacy Policy',
        headingNumber: "6",
        body: [{
            subHeadingNumber: "6.1",
            subHeadingBody: `Your use of the Platform is also governed by our Privacy Policy, which can be found [insert link to Privacy Policy]. Please review the Privacy Policy to understand how we collect, use, and protect your personal information.`
        }]
    },
    {
        heading: 'Intellectual Property',
        headingNumber: "7",
        body: [{
            subHeadingNumber: "7.1",
            subHeadingBody: `The content, features, and functionality of the Platform, including logos, trademarks, and other proprietary information, are protected by copyright, trademark, and other intellectual property laws.`
        }]
    },
    {
        heading: 'Limitation of Liability',
        headingNumber: "8",
        body: [{
            subHeadingNumber: "8.1",
            subHeadingBody: `The Plug is not liable for any direct, indirect, incidental, special, or consequential damages arising from the use of the Platform or the inability to use it, even if we have been advised of the possibility of such damages.`
        }]
    },
    {
        heading: 'Termination',
        headingNumber: "9",
        body: [{
            subHeadingNumber: "9.1",
            subHeadingBody: `The Plug may terminate or suspend your access to the Platform at any time, with or without cause, and without notice.`
        }]
    },
    {
        heading: 'Changes to the Terms',
        headingNumber: "10",
        body: [{
            subHeadingNumber: "10.1",
            subHeadingBody: `The Plug reserves the right to modify, amend, or update these Terms at any time. Your continued use of the Platform after such changes indicates your acceptance of the modified Terms.`
        }]
    },
    {
        heading: 'Governing Law and Jurisdiction',
        headingNumber: "11",
        body: [{
            subHeadingNumber: "11.1",
            subHeadingBody: `These Terms are governed by and construed in accordance with the laws of the Federal Republic of South Africa. Any legal action arising out of or related to these Terms must be filed exclusively in the state or federal courts located in the Federal Republic of South Africa.`
        }]
    },
    {
        heading: 'Additional Provisions and Disclaimers',
        headingNumber: "12",
        body: [
            {
                subHeadingNumber: "12.1",
                subHeadingBody: `Our diligent efforts are directed towards procuring the most advantageous deals available.`
            },
            {
                subHeadingNumber: "12.2",
                subHeadingBody: `We take pride in maintaining an uncomplicated approach. No complexities or embellishments!`
            },
            {
                subHeadingNumber: "12.3",
                subHeadingBody: `We may receive a commision on certain deals obtained through The Plug.`
            },
            {
                subHeadingNumber: "12.4",
                subHeadingBody: `We refrain from making explicit commitments to you or any other user of The Plug. Usage of The Plug is strictly at your own discretion and entails associated risks.`
            },
            {
                subHeadingNumber: "12.5",
                subHeadingBody: `We strongly advise against the disclosure of personal information on The Plug or any online platform.`
            },
            {
                subHeadingNumber: "12.6",
                subHeadingBody: `We reiterate that if you possess any inquiries or reservations, it is advisable to abstain from utilizing this site.`
            },
            {
                subHeadingNumber: "12.7",
                subHeadingBody: `Should you ever decide to discontinue your association with us, you have the option to delete your account via your Account Settings page. Please be aware that this action results in the permanent deletion of all your account data. Use caution when considering this step.`
            },
            {
                subHeadingNumber: "12.8",
                subHeadingBody: `For inquiries or assistance, we encourage you to submit a Help request through your Account Settings page, and we will be in touch.`
            }
        ]
    }
]