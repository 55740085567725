// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

export type RHFTextFieldProps = TextFieldProps & {
  name: string;
  onChangeExtension?: (newValue: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export default function RHFTextField({ name, helperText, onChangeExtension, ...other }: RHFTextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...otherFields }, fieldState: { error } }) => (
        <TextField
          {...otherFields}
          fullWidth
          value={typeof value === 'number' && value === 0 ? '' : value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          onChange={(newValue) => {
            onChange(newValue);
            onChangeExtension && onChangeExtension(newValue);
          }}
          {...other}
        />
      )}
    />
  );
}
