import axios from 'axios';
import { appConfig } from '../config';
import {
    AuthTokenRequestInterceptor,
    AuthTokenResponseInterceptor,
    CSRFTokenResponseInterceptor,
} from "./httpClientInterceptors";

const apiHttpClient = axios.create({
  withCredentials: true,
  baseURL: appConfig.plugsDealApi.baseUrl,
});

apiHttpClient.interceptors.request.use(AuthTokenRequestInterceptor, error => Promise.reject(error));
apiHttpClient.interceptors.response.use(AuthTokenResponseInterceptor, error => Promise.reject(error));
apiHttpClient.interceptors.response.use(CSRFTokenResponseInterceptor, error => Promise.reject(error));

const uniqueNeedsApi = {
    post: <T>(url: string, data: any) => apiHttpClient.post<T>(url, data),
    put: <T>(url: string, data?: any) => apiHttpClient.put<T>(url, data),
    delete: <T>(url: string, data?: any) => apiHttpClient.delete<T>(url, { data }),
    get: <T>(url: string, params?: object) => apiHttpClient.get<T>(url, { ...params })
};

const thirdPartyHttpClient = axios.create();

export {
    apiHttpClient, // exposed for testing
    uniqueNeedsApi,
    thirdPartyHttpClient
};
