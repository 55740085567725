//supported file types ref: https://github.com/sindresorhus/file-type/blob/main/core.js

import fileType from 'file-type';

const allowedFileSizeInMb = 4;
const allowedImageExtension = ['jpeg', 'jpg', 'png', 'apng', 'avif', 'gif', 'webp' ];

const fileIsofValidSize = (file: File): boolean => {
    const fileSizeInMb = file.size / 1024 / 1024; // in MiB
    return fileSizeInMb <= allowedFileSizeInMb;
};

const fileIsValidImage = async (file: File): Promise<boolean> => {
    const ft = await file.arrayBuffer().then(buffer => fileType(buffer));
    return (ft !== undefined && allowedImageExtension.indexOf(ft.ext) >= 0);
};

export const validateImage = async (file: File): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
        if (fileIsofValidSize(file)) {
            if (!await fileIsValidImage(file)){
                reject(`File with name ${file.name} is not a valid image.`)
            }
            resolve(true);
        }
        else {
            reject(`Please use a file less than ${allowedFileSizeInMb} Mb.`);
        }
    });
};

export const validateImages = async (files: FileList | File[]): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {

        Array.from(files).forEach((file: File) => {
            try {
                const valid = validateImage(file);
                if (!valid) { reject(`One or more images invalid or too large. Max allowed image size is ${allowedFileSizeInMb} Mb.`); }
            } catch (err) {
                reject(err);
            }
        });

        resolve(true);
    });
};