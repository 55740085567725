import Box from "@mui/material/Box/Box";
import { useAppLocation } from "context";
import { useHandleApiError } from "hooks";
import { useForm } from "react-hook-form";
import { FormProvider } from 'components';
import Grid from "@mui/material/Grid/Grid";
import { useEffect, useState } from 'react';
import Stack from "@mui/material/Stack/Stack";
import Divider from "@mui/material/Divider/Divider";
import Container from "@mui/material/Container/Container";
import Typography from "@mui/material/Typography/Typography";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { BrandSearch, DealsListCard, DealsListGrid, SelectSort, TagsSearch } from "features/components";
import { DealReadModel, FilterDealTypeBy, OrderDealsBy, QueryDealsRequest, useSearchDeals } from "api/deal";

export const ExploreDeals = () => {
    
    const methods = useForm({
        defaultValues: {
            orderBy: OrderDealsBy.LATEST,
            brand: { name: "" },
            tags: [],
            type: FilterDealTypeBy.All,
            isOfficial: true,
            pageSize: 20,
            pageIndex: 0,
        },
    });
    const { watch } = methods;
    const values = watch();

    // location
    const { userSelectedLocation: userLocation } = useAppLocation();

    // explore deals
    const [pageIndex, setPageIndex] = useState<number>(0);

    // search result state
    const [searchResults, setSearchResults] = useState<DealReadModel[]>([]);
    
    // query deal request hook
    const { data: searchData, isLoading: isQueryingDeals, isError, error, } = useSearchDeals({ 
        orderBy: values.orderBy,

        brand: values.brand.name,
        tags: values.tags,
        type: values.type,

        isOfficial: values.isOfficial,

        pageSize: values.pageSize,
        pageIndex: values.pageIndex,

        country: userLocation?.countryCode
    } as QueryDealsRequest, !!userLocation);
    useEffect(() => { searchData && setSearchResults(searchData.data); }, [searchData]);

    // handle search failure
    useHandleApiError(isError, error);

    // Merge and get unique elements based on the "id" property
    const mergeArray = (array1: DealReadModel[], array2: DealReadModel[]) => {
        return [...array1, ...array2]
        .reduce((accumulator: { [key: string]: DealReadModel }, current: DealReadModel) => {
            if (!accumulator[current.id]) {
                accumulator[current.id] = current;
            }
            return accumulator;
        }, {});
    };

    // handle search success
    useEffect(() => {
        if (searchData && searchData.data?.length > 0) {
            setSearchResults([...Object.values(mergeArray(searchResults, searchData.data))]);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData]);

    return (
        <Container maxWidth='lg' sx={{ mb: 10 }}>
            <FormProvider methods={methods}>

            <Grid container spacing={3} my={1}>
                <Grid item xs={12} md={7} container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <BrandSearch />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TagsSearch />
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }} alignItems='center' justifyContent='flex-end'>
                        <SelectSort name='type' label='Deal Type' options={Object.values(FilterDealTypeBy)} />
                        <SelectSort name='orderBy' label='Order By' options={Object.values(OrderDealsBy)} />
                    </Stack>
                </Grid>
                
            </Grid>

            </FormProvider>

            <Stack py={2}>

                <Divider sx={{ pb: 2 }} />

                <>
                {
                    !isQueryingDeals && searchResults ?
                    <>
                        {
                            // if user is searching and no results, show no results message
                            searchResults.length === 0 ?
                            <Box sx={{ display: 'flex', alignSelf: 'center', pt: 3 }}> <Typography color='text.secondary' variant="subtitle1"> Your filter returned no deals. Relax your filter and try again. </Typography> </Box>
                            :
                            // we found some results, display to user
                            <>
                                <DealsListGrid>
                                    { searchResults.map((deal, index) => ( <DealsListCard deal={deal} key={index} /> )) }
                                </DealsListGrid>

                                {
                                    searchData && searchData.hasNextPage &&
                                    <Stack m={5} direction="row" alignItems="center" justifyContent="center">
                                        <LoadingButton loading={isQueryingDeals} variant="outlined" size="large" onClick={() => setPageIndex(pageIndex + 1)}>
                                            Load More
                                        </LoadingButton>
                                    </Stack>
                                }
                            </>
                        }
                    </>
                    :
                    // We are probably laoding search results
                    <Searching />
                }
                </>
            </Stack>
        </Container>
    );
};

const Searching = () => <Box sx={{ display: 'flex', alignSelf: 'center' }}> <CircularProgress /> </Box>;