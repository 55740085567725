import jwtDecode, { JwtPayload } from "jwt-decode";
import { JWT_ACCESS_TOKEN_COOKIE_NAME } from "./constants";
import { clearCookie, getCookie, saveCookie } from "utils";

interface MyJwtPayload extends JwtPayload {
    accountId: string;
};

export const getAuthToken = () => getCookie(JWT_ACCESS_TOKEN_COOKIE_NAME);

export const clearAuthToken = () => clearCookie(JWT_ACCESS_TOKEN_COOKIE_NAME);

export const saveAuthToken = (authToken: string) => saveCookie(JWT_ACCESS_TOKEN_COOKIE_NAME, authToken);

export const resolveJwtPayload = (): MyJwtPayload | undefined => {
    const authToken = getAuthToken();
    return authToken ? jwtDecode<MyJwtPayload>(authToken) : undefined;
};

export const resolveTokenExpiry = () => {
    const authToken = getAuthToken();
    let tokenPayload;

    try {
        tokenPayload = jwtDecode<MyJwtPayload>(authToken);
    } catch (err) {
        return 0;
    }
    
    if (tokenPayload && tokenPayload.exp) {
        const expiryMilliseconds = tokenPayload.exp;
        return expiryMilliseconds;
    };

    return 0;
};

export const jwtTokenIsValid = (): boolean => (resolveTokenExpiry() * 1000) > (new Date()).getTime();