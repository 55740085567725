import { useState } from "react";
import { fShortenNumber } from "utils";
import Stack from "@mui/material/Stack/Stack";
import Dialog from "@mui/material/Dialog/Dialog";
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Divider from "@mui/material/Divider/Divider";
import { CopyToClipInputAdornment } from 'components';
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { DealReadModel, DealShareTypeEnum, useRecordDealShare } from "api/deal";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

type DealDetailsShareProps = {
    currentDeal: DealReadModel;
};

export const DealDetailsShareDialog = ({ currentDeal: currenDeal }: DealDetailsShareProps) => {

    // prepare api request to record need share
    // we fail silently and do not handle any errors
    // might need to log errors to something like sentry soon
    const { mutate: recordDealShareMutation } = useRecordDealShare(()=>{});

    // handle share dialog opne and close
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    // handle click to copy
    const currentUrl = window.location.href;
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(currentUrl);
        handleClick(DealShareTypeEnum.COPY);
    };

    // handle need share click
    const handleClick = (type: string) => {
        recordDealShareMutation({ dealId: currenDeal.id, shareType: type })
    };

    // social media hashtag
    const hashtag = "MyUniqueNeed";

    return (
        <>
            <Tooltip title='Share Need'>
                <Stack direction="row" alignItems="center" sx={{ m: 1 }} spacing={0}>
                    <IconButton onClick={handleOpenDialog}>
                        <ShareIcon fontSize='small' sx={{ color: 'text.secondary' }} />
                        <Typography id='un-need-details-share-count' sx={{ color: 'text.secondary', ml: 0.5 }} variant="subtitle2">{fShortenNumber(currenDeal.shares) || 0}</Typography>
                    </IconButton>
                </Stack>
            </Tooltip>
            
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle title="" >Share this Deal</DialogTitle>

                <Stack sx={{ mx: 2, mb: 2 }} spacing={2}>
                    <TextField
                        sx={{ minWidth: 320 }}
                        disabled
                        value={currentUrl}
                        type='text'
                        InputProps={{
                            endAdornment: 
                                <CopyToClipInputAdornment
                                    toolTipText='Click to Copy Url'
                                    successText='Deal Url Copied.'
                                    onClick={handleCopyToClipboard} 
                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault}
                                />
                        }}
                    />
                </Stack>
                
                <Divider sx={{ mx: 5, mb: 2 }} />
                
                <Stack direction='row' spacing={3} sx={{ mx: 2, mb:1 }} justifyContent='center'>
                    <FacebookShareButton onClick={() => handleClick(DealShareTypeEnum.FACEBOOK)} url={currentUrl} quote={"CampersTribe - World is yours to explore"} hashtag={hashtag} >
                        <FacebookIcon size={36} />
                    </FacebookShareButton>
                    
                    <TwitterShareButton onClick={() => handleClick(DealShareTypeEnum.TWITTER)} url={currentUrl} title={currenDeal.title} hashtags={[hashtag]} via='myuniqueneeds'>
                        <TwitterIcon size={36} />
                    </TwitterShareButton>

                    <WhatsappShareButton onClick={() => handleClick(DealShareTypeEnum.WHATSAPP)} url={currentUrl} title={currenDeal.title} separator=":: ">
                        <WhatsappIcon size={36} />
                    </WhatsappShareButton>
                </Stack>
            </Dialog>
        </>
    )
};

export * from './DealDetailHelmet';