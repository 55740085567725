import { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    notifyInfo,
    notifyError,
    MyReCaptcha,
    FormProvider,
    RHFTextField,
    ConfirmDialog,
    notifySuccess,
    CMDragAndDrop,
    FormInputErrorMessage,
} from "components";
import { appConfig } from 'config';
import { useHandleApiError } from "hooks";
import { uploadFileToS3 } from 'services';
import { Container } from '@mui/material';
import Card from '@mui/material/Card/Card';
import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack/Stack';
import { generateId, validateImage } from 'utils';
import { BrandReadModel, useCreateBrand } from 'api/brand';
import Typography from '@mui/material/Typography/Typography';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { CreateBrandDetails, createBrandValidationSchema, createBrandDefaultValues } from './create-brand-data';

export const CreateBrand = () => {

    // handle recaptcha
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    // image upload
    const uploadBrandProfilePic = async (file: File, imageId: string) => await uploadFileToS3({ bucketName: appConfig.aws.awsS3BucketAssets, fileId: imageId, fileToUpload: file });
  
    // create brand
    const { isLoading, isError, error, mutate: createBrandMutation } = useCreateBrand((response: BrandReadModel) => { notifySuccess('Brand Successfully created.'); });
    useHandleApiError(isError, error, undefined);

    const createBrand = async (data: CreateBrandDetails, recaptchaToken: string, file: File, showProgress?: () => void) => {
        notifyInfo("Uploading brand image...")
        const imageUploadSuccess = await uploadBrandProfilePic(file, data.brandPicId);
        showProgress && showProgress();

        if (imageUploadSuccess) {
            createBrandMutation({ ...data, recaptchaToken });
        } else {
            notifyError('Something went wrong uploading your brand image. Please select a valid image and try again.')
        }
    }

    return (
        <CreateBrandForm
            isHandlingSubmit={isLoading}
            handleCreateBrand={createBrand}
            recaptchaToken={recaptchaToken}
            setRecaptchaToken={setRecaptchaToken}
        />
    );
};


type CreateBrandFormProps = {
    isHandlingSubmit: boolean;
    recaptchaToken?: string | undefined | null;
    setRecaptchaToken: (val: string | null) => void;
    handleCreateBrand: (data: CreateBrandDetails, recaptchaToken: string, file: File, showProgress?: () => void) => void;
};
export const CreateBrandForm = ({ handleCreateBrand, isHandlingSubmit, setRecaptchaToken, recaptchaToken }: CreateBrandFormProps) => {

    const methods = useForm<CreateBrandDetails>({ resolver: yupResolver(createBrandValidationSchema), defaultValues: createBrandDefaultValues, mode: 'all' });
    const { handleSubmit, formState: { isSubmitting, isValid, isDirty, errors }, setValue } = methods;

    useEffect(() => console.log(JSON.stringify(errors)), [errors]);


    const [openConfirm, setOpenConfirm] = useState(false);
    const handleCloseConfirm = () => setOpenConfirm(false);
    const handleOpenConfirm = async () => setOpenConfirm(true);

    const [selectedImageValidity, setSelectedImageValidity] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<File>();
    const profilePicId = useRef<string>();
    const validateSelectedImage = async (files: FileList) => {
        try {
            const imageIsValid = await validateImage(files[0]);
            setSelectedImageValidity(imageIsValid);
            setSelectedImage(files[0]);
                
            // create imageId
            const imageId = generateId();
            profilePicId.current = imageId;
            setValue('brandPicId', imageId);

        } catch (error) {
            console.log(error)
            notifyError('Please select a valid image');
            setSelectedImageValidity(false);
            setSelectedImage(undefined);
        };
    };

    const [showSubmitting, setShowSubmitting] = useState<boolean>(false);
    const onSubmit = async (data: CreateBrandDetails) => {
      try {
        if (recaptchaToken) {
            setShowSubmitting(true);
            if (selectedImage && selectedImageValidity === true) {
                handleCreateBrand(data, recaptchaToken, selectedImage, () => setShowSubmitting(false));
            }  else {
                notifyInfo("Please select an image for your brand.");
                return;
            };

            setRecaptchaToken(null);
            handleCloseConfirm();
        } else {
            notifyInfo("Please complete the robot check.");
        }
      } catch (error) {
        notifyError("Something went wrong, please refresh your browser and try again.")
      }
    };

    return (
        <Container maxWidth='sm'>
            <Helmet>
                <title> The Plug | Create Brand </title>
            </Helmet>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        
                <Typography variant="h2" sx={{ pt: 3, pb: 2, color: 'text.secondary', textAlign: 'center' }}>
                    Create a new Brand
                </Typography>

                <Card>
                    {/* Brand Image Upload */}
                    <Stack px={3} pt={3}>
                        <CMDragAndDrop headerText='Upload an image for your brand below' allowMultiple={false} handleSelectedFiles={validateSelectedImage} />
                        { 
                            isDirty && errors.brandPicId ? 
                            (<FormInputErrorMessage message={errors.brandPicId.message} />) : 
                            null 
                        }
                    </Stack>

                    <Stack spacing={2} p={3}>
                        
                        {/* Brand Name */}
                        <RHFTextField name="name" label="Brand Name" />
        
                        {/* Brand Description */}
                        <RHFTextField
                            rows={3}
                            multiline
                            name="description"
                            label='Description'
                            placeholder='Tell us about your brand.' 
                        />
                        
                        {/* Brand Website */}
                        <RHFTextField name="website" label="Website" />
                        
                        {/* Api Key */}
                        <RHFTextField name="apiKey" label="Api Key" />
                        
                        {/* Ticket Id */}
                        <RHFTextField name="ticketId" label="Ticket Id" />
                        
                        {/* Recaptcha */}
                        <Stack>
                            <MyReCaptcha onRecaptchaChange={setRecaptchaToken} />
                        </Stack>
        
                        <LoadingButton loading={(isSubmitting || isHandlingSubmit || showSubmitting)} type="button" variant="contained" size="large" onClick={handleOpenConfirm} disabled={!isValid || !recaptchaToken}>
                            Create Brand
                        </LoadingButton>
                    </Stack>
                </Card>

                {/* Create Brand Confirmation */}
                <ConfirmDialog
                    open={openConfirm}
                    title="Create Brand?"
                    onClose={handleCloseConfirm}
                    content={
                        isSubmitting ? 
                        <i><em>Creating brand...</em></i>
                        : 
                        <>
                            <Typography gutterBottom>Are you sure you want to create this Brand? </Typography>
                        </>
                    }
                    action={
                        <LoadingButton onClick={() => handleSubmit(onSubmit)()} variant="contained" size="large" loading={isSubmitting || isHandlingSubmit}>
                            Yes, Create Brand!
                        </LoadingButton>
                    }
                />
            </FormProvider>
        </Container>
    );
};