import { useEffect, useState } from 'react';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';

import { useHandleApiError } from 'hooks';
import { fShortenNumber, fToNow, ls_DEAL_VIEW } from 'utils';
import {
  DealActivityReadModel,
  DealReadModel,
  useBookmarkDeal,
  useGetDealActivity,
  useRecordDealView,
  useRecordUpvoteDeal,
  useRemoveDealBookmark,
  useRemoveDealUpvote,
} from "api/deal";
import { Iconify } from 'components';
import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { DealDetailsShareDialog } from './details-share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography/Typography';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';

type DealDetailSnackBarBottomProps = {
    currentDeal: DealReadModel;
};

export const DealDetailSnackBarBottom = ({ currentDeal }: DealDetailSnackBarBottomProps) => {

    // api request to get deal activity on page load
    const { data, isLoading: isLoadingActivity, isError: isActivityError, error: activityError } = useGetDealActivity({ dealId: currentDeal.id });
    useHandleApiError(isActivityError, activityError);

    const [dealActivityData, setDealActivityData] = useState<DealActivityReadModel>();
    useEffect(() => data && setDealActivityData(data), [data]);
    const apiActivityCallback = (resp: DealActivityReadModel) => setDealActivityData(resp);

    // ================================================================= handle upvote ====================================================================

    const [isUpvoted, setIsUpvoted] = useState<boolean>(false);
    const [upvoteCount, setUpvoteCount] = useState(currentDeal.upvotes || 0);

    // api request to upvote deal
    const { isLoading: isUpvoting, isError: isErrorUpvoting, error: errorUpvoting, mutate: recorddealUpvoteMutation } = useRecordUpvoteDeal(apiActivityCallback);
    useHandleApiError(isErrorUpvoting, errorUpvoting);
    const handleUpvotedeal = async () => recorddealUpvoteMutation({ dealId: currentDeal.id });

    // api request to remove deal upvote
    const { isLoading: isRemovingUpvote, isError: isErrorRemovingUpvote, error: errorRemovingUpvote, mutate: removedealUpvoteMutation } = useRemoveDealUpvote(apiActivityCallback);
    useHandleApiError(isErrorRemovingUpvote, errorRemovingUpvote);
    const handleRemovedealUpvote = () => removedealUpvoteMutation({ dealId: currentDeal.id });

    // =============================================================== handle bookmark ===================================================================

    const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
    const [bookmarkCount, setBookmarkCount] = useState(currentDeal.bookmarks || 0);

    // api request to bookmark deal
    const { isLoading: isBookmarking, isError: isErrorBookmarking, error: errorBookmarking, mutate: bookmarkdealMutation } = useBookmarkDeal(apiActivityCallback);
    useHandleApiError(isErrorBookmarking, errorBookmarking);
    const handleBookmarkdeal = async () => bookmarkdealMutation({ dealId: currentDeal.id });

    // api request to remove deal bookmark
    const { isLoading: isRemovingBookmark, isError: isErrorRemovingBookmark, error: errorRemovingBookmark, mutate: removedealBookmarkMutation } = useRemoveDealBookmark(apiActivityCallback);
    useHandleApiError(isErrorRemovingBookmark, errorRemovingBookmark);
    const handleRemovedealBookmark = () => removedealBookmarkMutation({ dealId: currentDeal.id });

    // ================================================================ handle view =======================================================================

    const [viewCount, setViewCount] = useState<number>(currentDeal.views);

    // api request to record deal view
    // we do not handle error for recording view
    // we fail silently for now. we'd deal to log out to sentry or similar service soon
    const localStorageViewKey = ls_DEAL_VIEW(currentDeal.accountId, currentDeal.id);

    const hasVieweddeal = useReadLocalStorage<boolean>(localStorageViewKey);
    const [dealIsViewed, setdealIsViewed] = useLocalStorage<boolean>(localStorageViewKey, false);

    const { mutate: recorddealViewMutation } = useRecordDealView(() => setdealIsViewed(true));
    const handleRecorddealView = () => {

        // check if local storage has record of view recorded
        // this is just a micro optimization. 
        // even if we do not check and call the backend, we would not record a view twice for a single user.
        if (hasVieweddeal === true || dealIsViewed === true){
        return;
        }

        if (currentDeal) {
            recorddealViewMutation({ dealId: currentDeal.id });
        };
    };

    // ================================================================ handle updates ====================================================================

    useEffect(() => {
        if (dealActivityData) {

            // handle upvote
            setUpvoteCount(currentDeal.upvotes);
            dealActivityData.hasUpvotedDeal && setIsUpvoted(dealActivityData.hasUpvotedDeal);

            // handle bookmark
            setBookmarkCount(currentDeal.bookmarks)
            dealActivityData.hasBookmarkedDeal && setIsBookmarked(dealActivityData.hasBookmarkedDeal);

            // handle view count
            setViewCount(currentDeal.views);

            // handle deal view
            handleRecorddealView();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealActivityData, currentDeal]);

    return (
        <Stack direction="row" alignItems="center" sx={{ mx: 1 }} spacing={1}>
        {
            !isLoadingActivity && dealActivityData ? 
            <>
                {/* Created On */}
                <Stack direction='row' alignItems='center'>
                    <ScheduleIcon fontSize='small' sx={{ color: 'text.secondary' }} />
                    <Typography sx={{ color: 'text.secondary', ml: 0.5 }} variant="caption">{fToNow(currentDeal.createdOn)}</Typography>
                </Stack>
        
                <Box sx={{ flexGrow: 1 }} />

                <Stack  direction='row' spacing={0}>
                    {/* Views */}
                    <Tooltip title={ !viewCount || viewCount === 0 ? 'No one else has seen this' : `${viewCount} view${viewCount > 1 ? 's' : ''}`}>
                        <Stack direction='row' alignItems='center'>
                            <VisibilityIcon fontSize='small' sx={{ color: 'text.secondary', ml: 1 }} />
                            <Typography id='un-deal-details-view-count' sx={{ color: 'text.secondary', ml: 0.5 }} variant="subtitle2">{fShortenNumber(viewCount) || 0}</Typography>
                        </Stack>
                    </Tooltip>
            
                    {/* Share */}
                    <DealDetailsShareDialog currentDeal={currentDeal} />
            
                    {/* Bookmark */}
                    <Stack direction='row' alignItems='center'>
                        {
                        (isBookmarking || isRemovingBookmark) ?
                        <CircularProgress size={16} color="inherit" />
                        :
                        <Tooltip title={isBookmarked ? 'Remove deal Bookmark' : 'Bookmark deal'}>
                            <FormControlLabel
                            label={<Typography id='un-deal-details-bookmark-count' sx={{ color: 'text.secondary', m:0 }} variant="subtitle2">{fShortenNumber(bookmarkCount) || 0}</Typography>}
                            control={
                                <Checkbox 
                                    color="success" 
                                    checked={isBookmarked === true}
                                    checkedIcon={<BookmarkIcon fontSize='small' color='success' />}
                                    onChange={isBookmarked ? handleRemovedealBookmark : handleBookmarkdeal}
                                    icon={<BookmarkBorderIcon fontSize='small' sx={{ color: 'text.secondary' }} />}
                                />
                            }
                            />
                        </Tooltip>
                        }
                    </Stack>

                    {/* Upvote */}
                    <Stack direction='row' alignItems='center'>
                        {
                        currentDeal && 
                        <>
                            {
                            (isUpvoting || isRemovingUpvote) ?
                            <CircularProgress size={16} color="inherit" />
                            : 
                            <Tooltip title={!isUpvoted ? 'Upvote this deal to spread awareness' : 'Remove deal upvote.'}>
                                <FormControlLabel
                                label={<Typography id='un-deal-details-upvote-count' sx={{ color: 'text.secondary', m:0 }} variant="subtitle2">{fShortenNumber(upvoteCount) || 0}</Typography>}
                                control={
                                    <Checkbox 
                                        color="success" 
                                        checked={isUpvoted}
                                        name='un-deal-details-upvote'
                                        icon={<Iconify icon="bxs:upvote" />}
                                        checkedIcon={<Iconify icon="bxs:upvote" />} 
                                        onChange={isUpvoted ? handleRemovedealUpvote : handleUpvotedeal} 
                                    />
                                }
                                />
                            </Tooltip>
                            }
                        </>
                        }
                    </Stack>
                </Stack>
            </>
            :
            <Skeleton variant="text" sx={{ mx: 1, flexGrow: 1 }} />
        }
        </Stack>
    );
};