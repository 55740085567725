import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';
import PageNotFoundIllustration from '../assets/illustrations/PageNotFoundIllustration';

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title> 404 Page Not Found | The Plug</title>
      </Helmet>

      <Container maxWidth='lg'>
        <Stack m={3} alignItems='center'>
            <Typography variant="h3" paragraph>
                Hmmm, page not found!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
                We couldn't find the page you're looking for. Perhaps you've mistyped the URL? 
            </Typography>

            <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 }, }} />

            <Button component={RouterLink} to="/" size="large" variant="contained">
            Go Home
            </Button>
        </Stack>
      </Container>
    </>
  );
};