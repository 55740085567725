import { usePing } from "api/health"

export const Health = () => {
    const { isLoading, data, error } = usePing();

    if (isLoading) {
        return (<p> pinging api... </p>);
    }

    if (error) {
        return (<p> failed to reach api. </p>);
    }

    return (
        <p> Api Healthy with message : {data?.message} and requestId : {data?.requestId} </p>
    );
};