import { Channel, DefaultGenerics, StreamChat, User } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { AppUser } from 'context';
import { appConfig } from 'config';
import { useChatClient } from 'hooks';
import { DealReadModel } from 'api/deal';
import { resolveS3FileUrl } from 'services';

interface AppChatContextInterface {
    generalChatChannel: Channel<DefaultGenerics> | undefined;
    directMessageChannel: Channel<DefaultGenerics> | undefined;
    chatClient: StreamChat<DefaultStreamChatGenerics> | undefined;
};

const AppChatContext = createContext<AppChatContextInterface | undefined>(undefined);

const useAppChat = (): AppChatContextInterface => {
    const appChatContext = useContext(AppChatContext);

    if (appChatContext === undefined) {
        throw new Error ("invalid chat context.");
    };

    return appChatContext;
};

const resolveGeneralChatChannelId = (currentDeal: DealReadModel) => `tpd_gc_${currentDeal.id}`;

const useProvideAppChat = ({ chatToken, appUser, currentDeal }: { chatToken: string; appUser: AppUser; currentDeal?: DealReadModel; }): AppChatContextInterface => {

    // resolve app user profile pic
    const [userProfilePicUrl, setUserProfilePicUrl] = useState<string | undefined>(appUser?.profilePicId ? resolveS3FileUrl(appUser.profilePicId) : undefined);
    useEffect(() => appUser && setUserProfilePicUrl(resolveS3FileUrl(appUser.profilePicId)), [appUser]);

    const chatClient = useChatClient({
        apiKey: appConfig.streamChat.apiKey,
        tokenOrProvider: chatToken,
        user: {
          id: appUser.id,
          name: appUser.username,
          image: userProfilePicUrl,
        } as User
    });

    // resolve chat channels
    const [generalChatChannel, setGeneralChatChannel] = useState();
    useEffect(() => {
        if (chatClient) {
            if (currentDeal && currentDeal.id) {
                // resolve general chat channel
                const gcChannel = chatClient.channel('livestream', resolveGeneralChatChannelId(currentDeal)+"qwertyy", {
                    image: resolveS3FileUrl(currentDeal?.dealPicId),
                    name: currentDeal.title,
                    dealOwnerId: currentDeal.accountId,
                    dealId: currentDeal.id
                });
                const upsertGCChannel = async (channel: any) => {
                    await channel.watch();
                    setGeneralChatChannel(channel);
                };
                upsertGCChannel(gcChannel);
            };
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatClient, currentDeal, appUser]);

    return {
        chatClient,
        generalChatChannel
    } as AppChatContextInterface;
};

type AppChatProviderProps = {
    appUser: AppUser;
    chatToken: string; 
    children: ReactNode;
    currentDeal?: DealReadModel;
};

const AppChatProvider = ({ children, chatToken, appUser, currentDeal }: AppChatProviderProps) => {
    const appChatContextValue = useProvideAppChat({ chatToken, appUser, currentDeal });
    
    return (
        <AppChatContext.Provider value={appChatContextValue}>
            { children }
        </AppChatContext.Provider>
    );
};

export { AppChatProvider, useAppChat };