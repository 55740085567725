
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { useState } from 'react';
import Fade from '@mui/material/Fade';

export const UNInputAdornment = ({ adornmentValue = '$' }) => <InputAdornment position="start">{adornmentValue}</InputAdornment>;

export const UNPasswordInputAdornment = ({ 
    onClick = () => {},
    showPassword = false,
    onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {}}) => {
    return (
        <>
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    edge="end"
                >
                { showPassword === true ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
            </InputAdornment>
        </>
    )
};

export const UNClearSearchInputAdornment = ({
    displayClearIcon = false,
    onClick = () => {},
    onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {}}) => {
    return (
        <>
            <InputAdornment position="end">
                <IconButton
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    edge="end"
                >
                { displayClearIcon && <ClearIcon /> }
                </IconButton>
            </InputAdornment>
        </>
    )
};

export const CopyToClipInputAdornment = ({ 
    toolTipText = 'Click to copy',
    successText = 'Copied',
    onClick = () => {},
    onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {}}) => {
    
    const [open, setOpen] = useState(false);

    const handleOnClick = () => {
        onClick();
        setOpen(true);
    };

    return (
        <Tooltip title={toolTipText}>
            <>
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleOnClick}
                        onMouseDown={onMouseDown}
                        edge="end"
                    >
                    <ContentCopyIcon />
                    </IconButton>
                </InputAdornment>
                <Snackbar  
                    open={open} 
                    message={successText} 
                    autoHideDuration={1000} 
                    TransitionComponent={Fade}
                    onClose={() => setOpen(false)} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </>
        </Tooltip>
    )
};