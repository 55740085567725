import { Metric } from "web-vitals";
import reportWebVitals from './reportWebVitals';

const handleWebVitalMetrics = (metric: Metric) => {
    // just logging for now, but send to mixpanel or google analytics before prod
    console.log(`web vitals: { 'id': '${metric.id}', 'name': '${metric.name}', 'value': '${metric.value}' }`);
};

export const reportWebVitalMetrics = () => {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(handleWebVitalMetrics);
}