import Divider from "@mui/material/Divider/Divider";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { useCountdown } from "hooks";
import { useEffect, useState } from "react";
import { fToNow } from "utils";

export const CountDownLabel = ({ endDateTime, countdownFrom = 3 }: { endDateTime: Date, countdownFrom?: number }) => {

    const { days, hours, minutes, seconds } = useCountdown(new Date(endDateTime));

    const [timerColor, setTimerColor] = useState('black');
    useEffect(() => setTimerColor(+days < countdownFrom ? '#FF7276' : 'text.secondary'), [days, countdownFrom]);

    return (
        <Stack>
            <Divider sx={{ my: 2 }} />

            <Stack>
                <Typography textAlign='center' variant="body2"> <i> Deal ends in </i> </Typography>

                <Stack direction='row' spacing={2} justifyContent='center'>
                    <Stack direction='column' justifyContent='center'>
                        <Typography sx={{ color: timerColor }} variant="h4" > {days} </Typography>
                        <Typography sx={{ color: timerColor }} variant="body2"> Days </Typography>
                    </Stack>
                    {
                        +days < countdownFrom &&
                        <>
                            {+days > 0 && <Typography sx={{ color: 'text.secondary', pt: 2 }} textAlign='center' variant="subtitle1"> : </Typography> }
    
                        <Stack direction='column' justifyContent='center'>
                            <Typography sx={{ color: timerColor }}  variant="h4" > {hours} </Typography>
                            <Typography sx={{ color: timerColor }} variant="body2"> Hours </Typography>
                        </Stack>
    
                            {+hours > 0 && <Typography sx={{ color: 'text.secondary', pt: 2 }} textAlign='center' variant="subtitle1"> : </Typography> }
    
                        <Stack direction='column'>
                            <Typography sx={{ color: timerColor }}  variant="h4" > {minutes} </Typography>
                            <Typography sx={{ color: timerColor }} variant="body2"> Min </Typography>
                        </Stack>
    
                            {+minutes > 0 && <Typography sx={{ color: 'text.secondary', pt: 2 }} textAlign='center' variant="subtitle1"> : </Typography> }
    
                        <Stack direction='column'>
                            <Typography sx={{ color: timerColor }}  variant="h4" > {seconds} </Typography>
                            <Typography sx={{ color: timerColor }} variant="body2"> Sec </Typography>
                        </Stack>
                        </>
                    }
                </Stack>
            </Stack>
        </Stack>
    );
};


export const CountDownLabelLite = ({ endDateTime }: { endDateTime: Date }) => {

    const { days } = useCountdown(new Date(endDateTime));

    const [timerColor, setTimerColor] = useState('black');
    useEffect(() => setTimerColor(+days < 3 ? '#FF7276' : 'text.secondary'), [days]);

    return (
        <Typography sx={{ color: timerColor }} variant="caption"><i> Ends { fToNow(endDateTime) }</i> </Typography>
    );
};