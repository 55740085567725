// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import Stack from '@mui/material/Stack/Stack';
import Container from '@mui/material/Container/Container';

// stream chat
import 'stream-chat-react/dist/css/v2/index.css';

import { AppRoutes } from 'routes';
import { NavBar } from 'features/navbar';

const App = () => {
  return (
    <>
      {/** App Navigation */}
      <NavBar />
      
      {/** App */}
        <Container>
          <Stack sx={{ mb: 24 }} direction={'row'}>
            <AppRoutes />
          </Stack>
        </Container>
    </>
  )
};

export default App;
