import { CreateBrandRequest } from 'api/brand';
import { URL_REGEX } from 'services/constants';
import { object, SchemaOf, string } from 'yup';

export interface CreateBrandDetails extends Omit<CreateBrandRequest, 'recaptchaToken' > {};

export const createBrandValidationSchema: SchemaOf<CreateBrandDetails> = object().shape({
    name: string().min(2, 'Minimum 2 characters').max(64, 'Maximum 64 characters').required(),
    brandPicId: string().required(),
    description: string().min(10, 'Minimum 10 characters.').max(500, 'Maximum 500 characters').required(),
    website: string().matches(URL_REGEX, 'Invalid url').required('required'),
    apiKey: string().required(),
    ticketId: string().required()
}, []);

export const createBrandDefaultValues = {
    name: '',
    brandPicId: '',
    description: '',
    website: '',
    apiKey: '',
    ticketId: '',
};