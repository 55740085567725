import { useFormik } from "formik";
import { object, SchemaOf, string } from "yup";

import { useHandleApiError } from "hooks";
import { useCreateQuery } from "api/account";
import { ApiMessageResponse } from "api/interface";
import { UNDropDownMenu, CMTextField, notifySuccess } from "components";
import { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography/Typography";
import Card from "@mui/material/Card/Card";
import Stack from "@mui/material/Stack/Stack";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

export enum QueryTypes {
    Deals = 'deal', 
    Brand = 'brand', 
    Account = 'account', 
    General = 'general', 
};

export interface HelpData {
    queryType: string;
    title: string;
    description: string;
};

export const helpDataSchema: SchemaOf<HelpData> = object().shape({
    queryType: string()
        .oneOf(Object.values(QueryTypes))
        .required('required'),
    title: string()
        .min(2, 'Title too short. Atleast 2 Characters.')
        .max(50, 'Title too long. Max 50 characters.' )
        .required('required'),
    description: string()
        .min(24, 'Description too short. Atleast 24 characters.')
        .max(280, 'Description too long. Max 280 characters.' )
        .required("required"),
});

export const helpDataInitialValues: HelpData = {
    queryType: '',
    title: '',
    description: ''
};

export const UNHelp = () => {

    // submit changes to api
    const successCallback = async (response: ApiMessageResponse) => {
        notifySuccess(response.message);
    };
    const { isLoading, isError, error, mutate: createQueryMutation } = useCreateQuery(successCallback);
    useHandleApiError(isError, error);

    return ( <UNHelpForm isHandlingSubmit={isLoading} handleSubmit={createQueryMutation} /> )
};

type UNHelpFormProps = {
    isHandlingSubmit?: boolean;
    handleSubmit: (value: HelpData) => void;
};
export const UNHelpForm = ({ isHandlingSubmit: isLoading, handleSubmit }: UNHelpFormProps) => {
    
    const formikCMH = useFormik<HelpData>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: helpDataInitialValues,
        validationSchema: helpDataSchema,
        onSubmit: (values: HelpData, { resetForm }) => {
            handleSubmit(values); 
            resetForm();
        },
    });

    const [isHandlingSubmit, setIsHandlingSubmit] = useState<boolean|undefined>(isLoading);
    useEffect(() => setIsHandlingSubmit(isLoading), [isLoading]);

    return (
        <form onSubmit={formikCMH.handleSubmit}>
            <Typography textAlign="center" variant='body1' sx={{ color: 'text.secondary', margin: '0 0 11px 0' }}> 
                Query, concerns, compliments, suggestions...? We'd love to hear it all! Use the form below and we'd be in touch.
            </Typography>


            <Card sx={{ p: 3 }}>
            
                <Stack alignItems="flex-end">
                    <UNDropDownMenu
                        displayText='Query Type' 
                        dropDownOptions={Object.values(QueryTypes)}
                        handleSelectedOption={(selectedValue: string) => {
                            if (selectedValue !== undefined) {
                                formikCMH.setFieldValue('queryType', selectedValue, true);
                            }
                        }}
                        error={formikCMH.touched.queryType && Boolean(formikCMH.errors.queryType)}
                    />

                    <CMTextField
                        variant='filled'
                        type='text'
                        name='title'
                        label='Title'
                        onBlur={formikCMH.handleBlur}
                        onChange={formikCMH.handleChange}
                        value={formikCMH.values.title}
                        InputProps={{ disableUnderline: true, }}
                        helperText={ formikCMH.touched.title && formikCMH.errors.title }
                        error={formikCMH.touched.title && Boolean(formikCMH.errors.title)}
                    />

                    <CMTextField
                        variant='filled'
                        type='text'
                        name='description'
                        label='Description'
                        multiline
                        rows={3}
                        onBlur={formikCMH.handleBlur}
                        onChange={formikCMH.handleChange}
                        value={formikCMH.values.description}
                        InputProps={{ disableUnderline: true }}
                        helperText={ formikCMH.touched.description && formikCMH.errors.description }
                        error={formikCMH.touched.description && Boolean(formikCMH.errors.description)}
                    />
                    
                    <LoadingButton type='submit' variant="contained" size="medium" loading={isHandlingSubmit === true || formikCMH.isSubmitting}>
                        Send Query
                    </LoadingButton>
                </Stack>
            </Card>
        </form>
    )
};