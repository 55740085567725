import React from 'react';
import Typography from '@mui/material/Typography';

export type UNTypographyVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined;

interface UNTypographyInterface {
    content: string;
    textColor?: string;
    weight?: string;
    variant: UNTypographyVariant;
    style? : React.CSSProperties;
}

export const UNTypography = (props: UNTypographyInterface) => {
    return (
        <>
            <Typography 
                variant={props.variant} 
                color={props.textColor}
                sx={{fontWeight: props.weight}}
                style={{ color:`${props.textColor}`, ...props.style }}
            >
                {props.content}
            </Typography>
        </>
    );
};