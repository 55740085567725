import { useState, useRef, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import {
    MyReCaptcha,
    ConfirmDialog,
    FormProvider,
    RHFSwitch,
    RHFTextField,
    notifyError,
    notifyInfo,
    notifySuccess,
    CMDragAndDrop,
    FormInputErrorMessage,
    RHFSelect,
    notifyWarning,
} from "components";
import { appConfig } from 'config';
import { useHandleApiError } from "hooks";
import Grid from '@mui/material/Grid/Grid';
import Card from '@mui/material/Card/Card';
import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack/Stack';
import { ApiMessageResponse } from "api/interface";
import { useAppLocation } from 'context';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography/Typography';
import { BrandSearch, TagsSearch } from 'features/components';
import { generateId, snakeToTitle, validateImage } from 'utils';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import { DealTypes, RequestLocationData, useCreateDeal } from 'api/deal';
import { LocationData, uploadFileToS3 } from 'services';
import { CreateDealDetails, createDealDefaultValues, createDealValidationSchema } from './create-deal-data';

export const CreateDeal = () => {

    // handle recaptcha
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    // image upload
    const uploadDealPic = async (file: File, imageId: string) => await uploadFileToS3({ bucketName: appConfig.aws.awsS3BucketAssets, fileId: imageId, fileToUpload: file });
  
    // create deal
    const successCallback = (response: ApiMessageResponse) => {
        notifySuccess(response.message);
    };
    const { isLoading, isError, error, mutate: createDealMutation } = useCreateDeal(successCallback);
    useHandleApiError(isError, error, undefined, () => window.location.reload());

    const createDeal = async (data: CreateDealDetails, recaptchaToken: string, loc: LocationData, file?: File, showProgress?: () => void, callBack?: () => void) => {

        let imageUploadSuccess;
        if (file && data.dealPicId) {
            notifyInfo("Uploading deal image...")
            imageUploadSuccess = await uploadDealPic(file, data.dealPicId);
            showProgress && showProgress();
        };

        if (data.type === DealTypes.InstoreCoupon && data.dealPicId === data.brand.brandPicId) {
            notifyError('For In Store Coupon you must provide a picture of the coupon.');
            return;
        };

        if (file && data.dealPicId) {
            if (imageUploadSuccess) {
                createDealMutation({ ...data, brand: data.brand.name, recaptchaToken, location: loc as RequestLocationData, tags: data.tags });
                callBack && callBack();
            } else {
                notifyError('Something went wrong uploading your deal image. Please select a valid image and try again.')
            }
        } else if (!file) {
            createDealMutation({ ...data, brand: data.brand.name, recaptchaToken, location: loc as RequestLocationData, tags: data.tags });
        }
    }

    return (
        <CreateDealForm
            isHandlingSubmit={isLoading}
            handleCreateDeal={createDeal}
            recaptchaToken={recaptchaToken}
            setRecaptchaToken={setRecaptchaToken}
        />
    );
};


type CreateDealFormProps = {
    isHandlingSubmit: boolean;
    recaptchaToken?: string | undefined | null;
    setRecaptchaToken: (val: string | null) => void;
    handleCreateDeal: (data: CreateDealDetails, recaptchaToken: string, location: LocationData, file?: File, showProgress?: () => void, callBack?: () => void) => void;
};
export const CreateDealForm = ({ handleCreateDeal, isHandlingSubmit, setRecaptchaToken, recaptchaToken }: CreateDealFormProps) => {

    const methods = useForm<CreateDealDetails>({ resolver: yupResolver(createDealValidationSchema), defaultValues: createDealDefaultValues(), mode: 'all' });
    const { handleSubmit, control, formState: { isSubmitting, isValid, isDirty, errors }, watch, setValue, reset } = methods;
    const values = watch();

    useEffect(() => console.log(JSON.stringify(errors)), [errors])

    const [openConfirm, setOpenConfirm] = useState(false);
    const handleCloseConfirm = () => setOpenConfirm(false);
    const handleOpenConfirm = async () => setOpenConfirm(true);

    const [selectedImageValidity, setSelectedImageValidity] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<File>();
    const profilePicId = useRef<string>();
    const validateSelectedImage = async (files: FileList) => {
        try {
            const imageIsValid = await validateImage(files[0]);
            setSelectedImageValidity(imageIsValid);
            setSelectedImage(files[0]);
                
            // create imageId
            const imageId = generateId();
            profilePicId.current = imageId;
            setValue('dealPicId', imageId);

        } catch (error) {
            notifyError('Please select a valid image');
            setSelectedImageValidity(false);
            setSelectedImage(undefined);
        };
    };

    const { currentLocation: locationData } = useAppLocation();

    const [showSubmitting, setShowSubmitting] = useState<boolean>(false);
    const onSubmit = async (data: CreateDealDetails) => {
      try {
        if (recaptchaToken) {

            // verify location data present
            if (!locationData) {
              notifyWarning("We are not sure where you are. Please enable location and try again.");
              return;
            };

            const imageStateValid = (selectedImage && selectedImageValidity === true);

            setShowSubmitting(true);
            if (imageStateValid) {
                handleCreateDeal(data, recaptchaToken, locationData, selectedImage, () => setShowSubmitting(false), () => reset());
            }  else {
                notifyInfo("Please select an image for your deal or disable brand image override.");
                return;
            };

            setRecaptchaToken(null);
            handleCloseConfirm();
        } else {
            notifyInfo("Please complete the robot check.");
        }
      } catch (error) {
        notifyError("Something went wrong, please refresh your browser and try again.")
      }
    };

    return (
        <>
            <Helmet>
                <title>Create Deal</title>
            </Helmet>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        
                <Typography variant="h2" sx={{ py: 3, color: 'text.secondary', textAlign: 'center' }}>
                    Create a new Deal
                </Typography>

                <Grid container spacing={3}>

                    {/* Brand Details */}
                    <Grid item xs={12} md={5}>
                        <Card sx={{ p: 3 }}>
                            <Stack spacing={3} p={3}>
                            
                                {/* Deal Brand */}
                                <BrandSearch />

                                {/* Deal Type */}
                                <RHFSelect name="type" label="Deal Type">
                                    {Object.values(DealTypes).map((dealType, index) => (
                                        <MenuItem key={index} value={dealType}>
                                            {snakeToTitle(dealType)}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>


                                {/* Deal Image */}
                                <Stack px={3} py={0}>
                                    {/* Deal Image Upload */}
                                    <CMDragAndDrop headerText='Upload an image for the Deal' allowMultiple={false} handleSelectedFiles={validateSelectedImage} />
                                    { 
                                        isDirty && errors.dealPicId ? 
                                        (<FormInputErrorMessage message={errors.dealPicId.message} />) : 
                                        null 
                                    }
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>

                    {/* Deal Details */}
                    <Grid item xs={12} md={7}>
                        <Stack spacing={3}>
                            <Card sx={{ px: 3, py: 3 }}>
                                <Stack spacing={2} p={3}>

                                    {/* Deal Description */}
                                    <RHFTextField
                                        rows={3}
                                        multiline
                                        name="description"
                                        label='Description'
                                        placeholder='Describe the deal' 
                                    />
                                    
                                    {/* Deal Code */}
                                    { values.type === DealTypes.OnlineCode && <RHFTextField name="dealCode" label="Deal Code" /> }
                                    
                                    {/* Deal Start and End Date */}
                                    <Stack direction='row' spacing={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Controller
                                                name="startDate"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        minDate={new Date()}
                                                        onChange={(newValue: Date | null) => field.onChange(newValue)}
                                                        label="Start Date"
                                                        inputFormat="dd/MM/yyyy"
                                                        value={values.startDate}
                                                        renderInput={(params: any) => (
                                                            <TextField
                                                                name='startDate'
                                                                {...params}
                                                                fullWidth
                                                                error={errors.startDate}
                                                                helperText={errors.startDate && errors.startDate.message}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="endDate"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        minDate={values.startDate || new Date()}
                                                        onChange={(newValue: Date | null) => field.onChange(newValue)}
                                                        label="End Date"
                                                        inputFormat="dd/MM/yyyy"
                                                        value={values.endDate}
                                                        renderInput={(params: any) => (
                                                            <TextField
                                                                name='endDate'
                                                                {...params}
                                                                fullWidth
                                                                error={!!errors.endDate}
                                                                helperText={errors.endDate && errors.endDate.message}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                    
                                    {/* Deal Tags */}
                                    <TagsSearch />
                        
                                    {/* Api Key */}
                                    <RHFTextField name="apiKey" label="Api Key" />
                        
                                    {/* Ticket Id */}
                                    <RHFTextField name="ticketId" label="Ticket Id" />
                    
                                    {/* Deal Privacy */}
                                    <RHFSwitch
                                        name="isHidden"
                                        labelPlacement="start"
                                        sx={{ marginLeft: '2px' }}
                                        label={<Typography variant="body1" sx={{ color: 'text.secondary' }}> Make Deal Hidden? </Typography>}
                                        helperText="Hidden deals may not come up in search results. Users would need the deal link to view the deal."
                                    />
                                    
                                    {/* Recaptcha */}
                                    <Stack>
                                        <MyReCaptcha onRecaptchaChange={setRecaptchaToken} />
                                    </Stack>
                    
                                    <LoadingButton loading={(isSubmitting || isHandlingSubmit || showSubmitting)} type="button" variant="contained" size="large" onClick={handleOpenConfirm} disabled={!isValid || !recaptchaToken}>
                                        Create Deal
                                    </LoadingButton>
                                </Stack>
                            </Card>

                            {/* Create Deal Confirmation */}
                            <ConfirmDialog
                                open={openConfirm}
                                title="Create Deal?"
                                onClose={handleCloseConfirm}
                                content={
                                    isSubmitting ? 
                                    <i><em>Creating deal...</em></i>
                                    : 
                                    <>
                                        <Typography gutterBottom>Are you sure you want to create this Deal?</Typography>
                                        <i>PS: Fake deals will get you banned!</i> 
                                    </>
                                }
                                action={
                                    <LoadingButton onClick={() => handleSubmit(onSubmit)()} variant="contained" size="large" loading={isSubmitting || isHandlingSubmit}>
                                        Yes, Create Deal!
                                    </LoadingButton>
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    );
}