import { uniqueNeedsApi } from './httpClient';
import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, QueryFunctionContext, useQueryClient, useMutation } from '@tanstack/react-query';

type MyQueryKey = [string, object | undefined];
export type ApiErrorResponse = {
    statusCode?: number;
    message: string;
    error?: string;
}

const fetcher = <T>({queryKey, pageParam}: QueryFunctionContext<MyQueryKey>): Promise<T> => {
    const [url, params] = queryKey;

    return uniqueNeedsApi
    .get<T>(url, { params: { ...params, pageParam } })
    .then((res) => res.data);
};

// 'useMutation' ref: https://tanstack.com/query/v4/docs/reference/useMutation
/**
 * 
 * @param mutationFunction 
 * @param url primary use here is to, together with the params, generate a unique request key for react query client
 * @param params primary use here is to, together with the url, generate a unique request key for react query client
 * @param stateUpdater 
 * @param onSuccessCallback 
 * @param onErrorCallback 
 * @returns 
 */
const useGenericMutations = <T, S>(
    mutationFunction: (data: T | S) => Promise<AxiosResponse<S>>, 
    url: string,
    params?: object,
    stateUpdater?: ((oldData: T, newData: S) => T) | undefined,
    onSuccessCallback?: (data: S) => void,
    onErrorCallback?: (error: ApiErrorResponse | undefined) => void,
    retryCount?: number,
    retryDelayInMilliseconds?: number
) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<S>, AxiosError<ApiErrorResponse>, T | S>(
        mutationFunction,
        {
            // if the request is successful
            onMutate: async (data) => {
                // cancel any ongoing requests, we already have what we want
                await queryClient.cancelQueries([url!, params]);
                
                // get current data which includes data from success response
                const previousData = queryClient.getQueryData([url!, params]);

                // if stateUpdater function is defined, use that to mutate state 
                // else override state with new data
                queryClient.setQueryData<T>([url!, params], (oldData) => {
                    return stateUpdater ? stateUpdater(oldData!, data as S) : (data as T);
                });

                // return previous data
                return previousData;
            },
            // if the request is failed
            onError: (err, _, context) => {
                // If the mutation fails, use the context returned from onMutate to roll back
                queryClient.setQueryData([url!, params], context);
                if (onErrorCallback) {
                    onErrorCallback(err.response?.data);
                }
            },
            // if the request is either successful or failed
            onSettled: () => {
                // invalidate query to keep state fresh
                queryClient.invalidateQueries([url!, params]);
            },
            onSuccess: (response, variables, context) => {
                if (onSuccessCallback) {
                    onSuccessCallback(response.data as S);
                }
            },
            retryDelay: retryDelayInMilliseconds || 1000,
            retry: (failureCount: number, error: AxiosError<ApiErrorResponse>) => handleRetries(failureCount, error, retryCount),
        },
    );
};

export const usePost = <T, S>(
    url: string,
    params?: object,
    onSuccessCallback?: (data: S) => void,
    stateUpdater?: (oldData: T, newData: S) => T,
    onErrorCallback?: (error: ApiErrorResponse | undefined) => void,
    retryCount?: number,
    retryDelayInMilliseconds?: number
) => {
    return useGenericMutations<T, S>(
        (data) => uniqueNeedsApi.post<S>(url, data),
        url,
        params,
        stateUpdater,
        onSuccessCallback,
        onErrorCallback,
        retryCount ?? 0,
        retryDelayInMilliseconds
    );
};

// 'useQuery' ref: https://tanstack.com/query/v4/docs/reference/useQuery
export const useFetch = <T>(
    url: string | null | undefined, 
    params?: object,
    isEnabled?: boolean,
    retryCount?: number,
    retryDelayInMilliseconds?: number) => {
    return useQuery<T, AxiosError<ApiErrorResponse>, T, MyQueryKey>(
        [url!, params],
        ({ queryKey, meta }) => fetcher({ queryKey, meta }),
        {
            enabled: !!url && isEnabled,
            retryDelay: retryDelayInMilliseconds || 1000,
            retry: (failureCount: number, error: AxiosError<ApiErrorResponse>) => handleRetries(failureCount, error, retryCount),
        },
    );
};

export const usePut = <T, S>(
    url: string,
    params?: object,
    onSuccessCallback?: (data: S) => void,
    stateUpdater?: (oldData: T, newData: S) => T,
    onErrorCallback?: (error: ApiErrorResponse | undefined) => void,
    retryCount?: number,
    retryDelayInMilliseconds?: number
) => {
    return useGenericMutations<T, S>(
        (data) => uniqueNeedsApi.put<S>(url, data),
        url,
        params,
        stateUpdater,
        onSuccessCallback,
        onErrorCallback,
        retryCount,
        retryDelayInMilliseconds
    );
};

export const useDelete = <T, S>(
    url: string,
    params?: object,
    onSuccessCallback?: (data: S) => void,
    stateUpdater?: (oldData: T, newData: S) => T,
    onErrorCallback?: (error: ApiErrorResponse | undefined) => void,
) => {
    return useGenericMutations<T, S>(
        (data) => uniqueNeedsApi.delete<S>(url, data),
        url,
        params,
        stateUpdater,
        onSuccessCallback,
        onErrorCallback,
    );
};


/**
 * exposed for testing
 */
export const handleRetries = (failureCount: number, error: AxiosError<ApiErrorResponse>, retryCount: number | undefined): boolean => {
    if (error && error.response && error.response.status) {
        switch(+error.response.status) { 
            case 400: {
                // bad request
               return false; 
            } 
            case 401: {
                // unauthorized
               return false; 
            } 
            case 403: {
                // forbidden
                return false;
            } 
            case 404: {
                // not found
                return false;
            } 
            case 500: {
                // internal server error
                return false;
            } 
            default: { 
                if (retryCount && retryCount > 0) {
                    return failureCount < retryCount;
                }
                return false;
            } 
        }
    }
    else {
        return failureCount < (retryCount || 2);
    };
};