import { useEffect, useState } from 'react';

import { useHandleApiError } from 'hooks';
import { RHFAutocomplete } from 'components';
import { useSearchTags } from 'api/brand';

export const TagsSearch = () => {

  // query state
  const [tagQuery, setTagQuery] = useState<string>();

  // search result state
  const [searchResults, setSearchResults] = useState<string[]>([]);
  
  // search tag request hook
  const { data: searchData, isLoading, isError, error } = useSearchTags({ q: tagQuery! }, !!tagQuery);
  useEffect(() => { searchData && setSearchResults(searchData); }, [searchData]);
  useHandleApiError(isError, error);

  // update tag query to trigger re-render so that react query makes new api call
  const handleSearchTags = async (searchText: string) => {
    if (!searchText || searchText.length < 2) { return; }
    setTagQuery(searchText);
  };

  return (
    <RHFAutocomplete
      multiple
      freeSolo={false}
      name='tags'
      label='Deal Tags (Categories)'
      disableClearable
      filterOptions={(x) => x}
      placeholder='Search Tags'
      forcePopupIcon={false}
      loading={!!tagQuery && tagQuery.length > 1 && isLoading}
      options={searchResults}
      ChipProps={{ size: 'small' }}
      isOptionEqualToValue={(option, value) => option === value}
      onInputChange={(_, value) => handleSearchTags(value)}
      noOptionsText={ !!tagQuery && tagQuery.length > 1 ?  `Tag '${tagQuery}' not found.` : 'Type to search tags...' }
    />
  )
};