import { Route, Routes } from "react-router-dom";

import { AppPages } from "routes";
import { NotFound } from "components";
import { Health } from 'features/admin';
import { AuthSuccess } from 'features/auth';
import { ProtectedRoute } from "./ProtectedRoute";
import { AccountSettings } from "features/account";
import { CreateDeal, DealDetailPage, ExploreDeals } from "features/deal";
import { CreateBrand, ManageBrandDetails } from "features/brand";
import { TermsOfService } from "features/components";


export const AppRoutes = () => {
    
    return (
        <>
            <Routes>

                {/* ========================================== Public  Routes ========================================== */}

                {/* General */}
                <Route path={AppPages.Health} element={<Health />} />
                <Route path={AppPages.NotFound} element={<NotFound />} />

                {/* Homepage */}
                <Route path={AppPages.Home} element={<ExploreDeals />} />

                {/* Deals */}
                <Route path={AppPages.ViewDeal} element={<DealDetailPage />} />

                {/* Term of Service */}
                <Route path={AppPages.TermsAndCondition} element={<TermsOfService />} />

                {/* ==================================================================================================== */}

                {/* ========================================== Auth Routes ============================================= */}

                {/* User Auth Pages */}
                <Route path={AppPages.AuthSuccess} element={<AuthSuccess />} />

                {/* ==================================================================================================== */}

                {/* ========================================= Protected Routes ========================================= */}

                {/* General Authenticated Routes with no extra permissions required */}
                <Route element={<ProtectedRoute />}>

                    {/* Account */}
                    <Route path={AppPages.AccountSettings} element={<AccountSettings />} />

                    {/* Deals */}
                    <Route path={AppPages.CreateDeal} element={<CreateDeal />} />

                    {/* Brands */}
                    <Route path={AppPages.CreateBrand} element={<CreateBrand />} />
                    <Route path={AppPages.ManageBrandDetails} element={<ManageBrandDetails />} />

                </Route>

                {/* ==================================================================================================== */}
            </Routes>
        </>
    );
};