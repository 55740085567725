import Chip from '@mui/material/Chip/Chip';
import Paper from '@mui/material/Paper/Paper';
import { SxProps, Theme, styled } from '@mui/material/styles';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

type DealTagsProps = {
    tags: string[];
    sx?: SxProps<Theme>;
};
export const DealTags = ({ tags, sx }: DealTagsProps) => {
    return (
        <Paper sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', listStyle: 'none', ...sx }} component="ul">
        {
            tags.map((tag, index) => {
                return (
                    <ListItem key={index}>
                    <TagChip index={index} tag={tag} />
                    </ListItem>
                );
            })
        }
        </Paper>
    );
};

type TagChipProps = {
    index: number;
    tag: string;
};
export const TagChip = ({ index, tag }: TagChipProps) => 
<Chip key={`${index}-${tag}`} variant='filled' sx={{ mr: 0.5, color: 'text.secondary' }} label={tag} color='default' />