import { AppUser } from 'context';
import { object, string, SchemaOf } from 'yup';
import { USERNAME_REGEX } from 'services';

// Account Profile
export interface AccountProfileDetails {
    profilePicId?: string;
    email: string;
    firstname?: string;
    lastname?: string;
    bio?: string;
    username?: string;
    gender?: string;
};

export enum Gender {
    Male = 'Male',
    Female = 'Female'
};

export const accountProfileValidationSchema: SchemaOf<AccountProfileDetails> = object().shape({
    email: string().required(),

    //---------- editable ----------//
    profilePicId: string().notRequired(), // #hack ---> edited directly. see function below (resolveUpdatedFields)
    firstname: string()
        .when("firstname", (val, schema) => {
            if (val?.length > 0) {
                return string()
                .min(1, 'firstname too short. atleast 1 characters')
                .max(64, 'firstname too long. Max 64 characters.' )
                .required()
            } else { 
                return string().notRequired();
            }
        }),
    lastname: string()
        .when("lastname", (val, schema) => {
            if (val?.length > 0) {
                return string()
                .min(1, 'lastname too short. atleast 1 characters')
                .max(64, 'lastname too long. Max 64 characters.' )
                .required()
            } else { 
                return string().notRequired();
            }
        }),
    username: string()
        .when("username", (val, schema) => {
            if (val?.length > 0) {
                return string()
                    .matches(new RegExp(USERNAME_REGEX), 'Invalid username')
                    .required();
            } else { 
                return string().notRequired();
            }
        }),
    bio: string()
        .when("bio", (val, schema) => {
            if (val?.length > 0) {
                return string()
                .min(24, 'Bio too short. Atleast 24 characters.')
                .max(280, 'Bio too long. Max 280 characters.' )
                .required()
            } else { 
                return string().notRequired();
            }
        }),
    gender: string()
        .when("gender", (val, schema) => {
            if (val?.length > 0) {
                return string().oneOf(Object.values(Gender)).required('required')
            } else { 
                return string().notRequired();
            }
        }),
}, [
    ["firstname", "firstname"],
    ["lastname", "lastname"],
    ["username", "username"],
    ["gender", "gender"],
    ["bio", "bio"]
]);

export const accountProfileDefaultValues = (appUser: AppUser): AccountProfileDetails => {
    return {
        firstname: appUser?.firstName || '',
        lastname: appUser?.lastName || '',
        email:  appUser?.email || '',
        profilePicId: appUser?.profilePicId || '',
        username: appUser?.username || '',
        bio: appUser?.bio || '',
        gender: appUser?.gender || ''
    } as AccountProfileDetails;
};

export const resolveUpdatedFields = (values: AccountProfileDetails, appUser: AppUser, imageId: string | undefined) => {

    var updatedFields: Record<string,any> = {};
    
    if (imageId && imageId !== appUser.profilePicId) {
        updatedFields.profilePicId = imageId;
    }
    
    if (values.firstname && values.firstname !== appUser.firstName) {
        updatedFields.firstName = values.firstname;
    }
    
    if (values.lastname && values.lastname !== appUser.lastName) {
        updatedFields.lastName = values.lastname;
    }
    
    if (values.username && values.username !== appUser.username) {
        updatedFields.username = values.username;
    }
    
    if (values.bio && values.bio !== appUser.bio) {
        updatedFields.bio = values.bio;
    }
    
    if (values.gender && values.gender !== appUser.gender) {
        updatedFields.gender = values.gender;
    }

    return updatedFields;
};


// Deactivate Account
export interface DeleteAccountDetails {
    email: string;
    delete: string;
}

export const deactivateAccountDetailSchema: SchemaOf<DeleteAccountDetails> = object().shape({
    email: string()
        .email('invalid email address')
        .required('email is required.'),
    delete: string()
        .matches(new RegExp(/^delete$/i), "type in the word 'delete' ")
        .required("type the wrod 'delete' to delete your account"),
});

export const deactivateAccountDetailActivationValues: DeleteAccountDetails = {
    email: '',
    delete: ''
};