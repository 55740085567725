import styled from 'styled-components';
import { TextField as MuiTextField } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

type InputProps = { 
    id?: string,
    size?: 'small' | 'regular' | 'large'
};

export const CMTextField = MuiStyled(MuiTextField)({
    marginBottom: "11px",
    width: '100%',

    "& .MuiInputBase-root": {
        borderRadius: "8px",
    },
});

const FormInputBase = styled.input<InputProps>`
    padding: 20px 30px; 
    line-height: 20px;
    text-align: left;
    color: var(--dim-color);
    background-color: var(--smoke-color);
    letter-spacing: 0px;
    border-radius: 6px;
    opacity: 1;
    font: normal normal 800 15px/20px FontBold;
    border: none;
    outline: none;

    @media (max-width: 640px) {
        padding: 11px 12px; 
        line-height: 18px;
        font: normal normal 500 13px/18px FontBold;
    }

    &:hover {
        background-color: var(--secondary-hover-color);
    }
`;

export const FormInput = styled(FormInputBase)`

`;

export const FormInputError = styled.p`
    max-width: inherit;
    height: auto;
    display: block;
    margin-top: -7px;
    margin-bottom: 11px;
    text-align: left;
    align-self: center;
    opacity: 1;
    color: var(--muted-color);
    margin-top: 0;
    margin-bottom: 15px;
    color: var(--error-color);
    font: normal normal 800 12px/17px FontRegular;

    &:before {
        display: inline;
    }

    @media (max-width: 640px) {
        height: 14px;
        font: normal normal 500 10px/14px FontRegular;
    }
`;

export const FormInputErrorMessage = ({ message = '' }) => {
    return (
        <FormInputError> { message } </FormInputError>
    );
};