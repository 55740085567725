import { useState } from "react";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Typography from "@mui/material/Typography/Typography";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { FormProvider, RHFTextField, notifySuccess, notifyWarning } from "components";
import { useForm } from "react-hook-form";
import { SchemaOf, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack/Stack";
import { useDeleteDeal } from "api/deal";
import { ApiMessageResponse } from "api/interface";
import { useHandleApiError } from "hooks";

interface DeleteDealdDialogProps {
    dealId: string;
};

export const DeleteDealDialog = ({ dealId }: DeleteDealdDialogProps) => {

    // api call to delete deal
    const { isLoading: isDeletingDeal, isError: isErrorDeleteNeed, error: errorDeleteNeed, mutate: deleteDealMutation } = useDeleteDeal((resp: ApiMessageResponse) => notifySuccess(resp.message));
    useHandleApiError(isErrorDeleteNeed, errorDeleteNeed);

    // form data
    const methods = useForm<DeleteDealsDetails>({ resolver: yupResolver(deleteDealsDetailSchema), defaultValues: deleteDealsDetailActivationValues, mode: 'all' });
    const { reset, handleSubmit, formState: { isValid, isSubmitting, isDirty }} = methods;

    const [openDeleteDeal, setOpenDeleteDeal] = useState(false);
    const handleOpen = () => setOpenDeleteDeal(true);
    const handleClose = () => setOpenDeleteDeal(false);
    
    // handle cancel
    const onCancel = () => handleClose();

    // handle submit

    const onSubmit = (val: DeleteDealsDetails) => {
        if (!isValid) {
            notifyWarning("Api required to delete deal");
            return;
        };

        deleteDealMutation({ dealId, apiKey: val.apiKey });

        onCancel();
        reset();
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Button onClick={handleOpen} color='error' variant="outlined" size="small">
                Delete Deal
            </Button>
            <Dialog open={openDeleteDeal} onClose={handleClose}>
                <DialogTitle> Delete Deal </DialogTitle>

                <DialogContent>
                    {
                        isDeletingDeal ? 
                        <Typography variant="body2" sx={{ color: 'text.primary', pb: 3 }}>
                            <b>Deleting deal...</b>
                        </Typography>
                        : 
                        <Stack spacing={2}>
                            <Typography variant="body2" sx={{ color: 'text.primary', pb: 3 }}>
                                <b>Deleting a Deal is NOT reversible. We erase the data completely from our systems. Like it never happened! </b>
                            </Typography>

                            <RHFTextField fullWidth name="apiKey" label="Api Key" />

                            <RHFTextField fullWidth name="delete" placeholder="Type the word 'delete'" />

                            <Typography variant="body2" sx={{ color: 'text.primary', pb: 3 }}>
                                Are you sure you want to <b>Delete this Deal?</b> 
                            </Typography>
                        </Stack>
                    }
                </DialogContent>

                <DialogActions>
                    <LoadingButton disabled={!(isValid && isDirty)} onClick={() => handleSubmit(onSubmit)()} size="large" type="button" color="error" variant="contained" loading={isDeletingDeal || isSubmitting}>
                        Yes, Delete Deal!
                    </LoadingButton>

                    <Button color="inherit" variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    )
};


export interface DeleteDealsDetails {
    apiKey: string;
    delete: string;
}

export const deleteDealsDetailSchema: SchemaOf<DeleteDealsDetails> = object().shape({
    apiKey: string().required('Api key is required to delete deal.'),
    delete: string()
        .matches(new RegExp(/^delete$/i), "type in the word 'delete' ")
        .required("type the word 'delete' to delete this deal"),
});

export const deleteDealsDetailActivationValues: DeleteDealsDetails = {
    apiKey: '',
    delete: ''
};
