import { useFormik } from "formik";
import { useEffect, useState } from "react";

import {
    CMTextField,
    DragAndDropImage,
    LoadingSpinner,
    UNDropDownMenu,
    notifyInfo,
    notifySuccess,
} from "components";
import Box from "@mui/material/Box/Box";
import { useHandleApiError } from "hooks";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import { CMDragAndDrop } from 'components';
import { resolveS3FileUrl } from "services";
import Stack from "@mui/material/Stack/Stack";
import Divider from "@mui/material/Divider/Divider";
import { AppUser, useAppAuth, useImageUpload } from "context";
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import avatarPlaceholder from 'assets/icons/avatar/ic_avatar.png';
import { GetAccountResponse, useUpdateAccount } from "api/account";
import { accountProfileDefaultValues, AccountProfileDetails, accountProfileValidationSchema, Gender, resolveUpdatedFields } from "./settings-data";

type AccountProfileProp = {
    appUser: AppUser;
};

export const AccountProfile = ({ appUser }: AccountProfileProp) => {
    
    const { setAppUser } = useAppAuth();

    // submit changes to api
    const updateAccountSuccessCallback = async (response: GetAccountResponse) => {
        setAppUser(response);
        notifySuccess("Account successfully updated.");
    };
    const { isLoading, isError, error, mutate: updateAccountMutation } = useUpdateAccount(updateAccountSuccessCallback);
    useHandleApiError(isError, error);

    return ( <AccountProfileForm appUser={appUser} isHandlingSubmit={isLoading} handleSubmit={updateAccountMutation} /> );
};

type AccountProfileFormProps = {
    appUser: AppUser;
    isHandlingSubmit?: boolean;
    handleSubmit: (values: Record<string, any>) => void;
};

export const AccountProfileForm = ({ appUser, handleSubmit, isHandlingSubmit }: AccountProfileFormProps) => {

    // image selection
    const { imageValid, selectedImageUrl, validateSelectedImage, uploadSelectedImage } = useImageUpload();
    const [ userProfilePicUrl, setUserProfilePicUrl ] = useState<string | undefined>(appUser ? resolveS3FileUrl(appUser.profilePicId) : avatarPlaceholder);
    useEffect(() => { selectedImageUrl && setUserProfilePicUrl(selectedImageUrl); }, [selectedImageUrl]);
    useEffect(() => { appUser && setUserProfilePicUrl(resolveS3FileUrl(appUser.profilePicId)); }, [ appUser ]);

    const formikAP = useFormik<AccountProfileDetails>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: accountProfileDefaultValues(appUser),
        validationSchema: accountProfileValidationSchema,
        onSubmit: async (values: AccountProfileDetails) => {

            let imageId = undefined;
            if (selectedImageUrl && imageValid) {
                notifyInfo("uploading image...");
                imageId = await uploadSelectedImage();
            };

            const updatedFields = resolveUpdatedFields(values, appUser, imageId);
            if (Object.keys(updatedFields).length > 0) {
                handleSubmit(updatedFields);
            };
        },
    });



    return (
        <form onSubmit={formikAP.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3, textAlign: 'center', borderRadius: '12px' }}>
                        <CMDragAndDrop allowMultiple={false} headerText='Update your profile picture below' handleSelectedFiles={(files) => validateSelectedImage(files[0])}>
                            { userProfilePicUrl ? <DragAndDropImage imageUrl={userProfilePicUrl} /> : <LoadingSpinner /> }
                        </CMDragAndDrop>
                    </Card>
                </Grid>
        
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 3 }}>

                        <CMTextField variant='filled' type='text' label='Email' name='email' value={formikAP.values.email} InputProps={{ disableUnderline: true }} disabled />

                        <Divider style={{ margin: '11px 11px 22px 11px' }} flexItem />

                        <Box columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', }} >
                            <CMTextField 
                                variant='filled' 
                                type='text' 
                                label='First Name' 
                                name='firstname' 
                                onBlur={formikAP.handleBlur}
                                onChange={formikAP.handleChange}
                                value={formikAP.values.firstname}
                                InputProps={{ disableUnderline: true }} 
                                helperText={ formikAP.touched.firstname && formikAP.errors.firstname }
                                error={formikAP.touched.firstname && Boolean(formikAP.errors.firstname)}
                            />

                            <CMTextField 
                                variant='filled' 
                                type='text' 
                                label='Last Name' 
                                name='lastname' 
                                onBlur={formikAP.handleBlur}
                                onChange={formikAP.handleChange}
                                value={formikAP.values.lastname}
                                InputProps={{ disableUnderline: true }} 
                                helperText={ formikAP.touched.lastname && formikAP.errors.lastname }
                                error={formikAP.touched.lastname && Boolean(formikAP.errors.lastname)}
                            />
                        </Box>
                        
                        <UNDropDownMenu
                            displayText="Gender"
                            style={{ textTransform: "capitalize" }}
                            dropDownOptions={Object.values(Gender).sort()}
                            handleSelectedOption={(selectedValue: string) => formikAP.setFieldValue('gender', selectedValue, true)}
                        />
            
                        <Stack alignItems="flex-end">

                            <CMTextField 
                                variant='filled' 
                                type='text' 
                                label='Username' 
                                name='username' 
                                onBlur={formikAP.handleBlur}
                                onChange={formikAP.handleChange}
                                value={formikAP.values.username}
                                InputProps={{ disableUnderline: true }} 
                                helperText={ formikAP.touched.username && formikAP.errors.username }
                                error={formikAP.touched.username && Boolean(formikAP.errors.username)}
                            />

                            <CMTextField
                                variant='filled'
                                type='text'
                                name='bio'
                                label='Bio'
                                multiline
                                rows={3}
                                onBlur={formikAP.handleBlur}
                                onChange={formikAP.handleChange}
                                value={formikAP.values.bio}
                                InputProps={{ disableUnderline: true }}
                                helperText={ formikAP.touched.bio && formikAP.errors.bio }
                                error={formikAP.touched.bio && Boolean(formikAP.errors.bio)}
                            />
                            
                            <LoadingButton type='submit' variant="contained" size="medium" loading={isHandlingSubmit || formikAP.isSubmitting}>
                                Update Account
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </form>
    )
};