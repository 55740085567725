import { useState } from 'react';
import { DealReadModel } from 'api/deal';
import { AppUser, useAppAuth } from 'context';
import { GeneralChat } from 'features/components';
import Card from '@mui/material/Card/Card';
import Tabs from '@mui/material/Tabs/Tabs';
import Box from '@mui/material/Box/Box';
import Tab from '@mui/material/Tab/Tab';
import { DealAdsTab } from './DealAdsTab';
import Typography from '@mui/material/Typography/Typography';

interface DealDetailSections {
    key: string;
    heading: string;
    component: React.ReactNode;
};

enum DealDetailTabs {
    Ads = 'Ads',
    GeneralChat = 'Chat'
};

const resolveDealDetailTabs = (currentDeal: DealReadModel, appUser: AppUser): DealDetailSections[] => {

    const tabs: DealDetailSections[] = [];

    // always add ads tab
    tabs.push({
        key: DealDetailTabs.Ads.toLowerCase(),
        heading:  DealDetailTabs.Ads,
        component: <DealAdsTab />
    });

    // Add General Message box if deal is not closed and user logged in
    const canChat = appUser?.id && currentDeal.id && !currentDeal.isClosed && !currentDeal.isLocked;
    if (canChat) {
        if (!appUser.profileComplete || appUser.toDos.length > 0) {
            tabs.push({
              key: DealDetailTabs.GeneralChat.toLowerCase(),
              heading: DealDetailTabs.GeneralChat,
              component: <Typography textAlign='center' sx={{ color: 'text.secondary', pt: 1, pb: 3, px: 2 }} variant="body1"> Complete your profile chat and get community tips about this and more deals  </Typography>
            });
        }

      tabs.push({
        key: DealDetailTabs.GeneralChat.toLowerCase(),
        heading: DealDetailTabs.GeneralChat,
        component: <GeneralChat currentDeal={currentDeal} />
      });
      
    } else {
        tabs.push({
          key: DealDetailTabs.GeneralChat.toLowerCase(),
          heading: DealDetailTabs.GeneralChat,
          component: <Typography textAlign='center' sx={{ color: 'text.secondary', pt: 1, pb: 3, px: 2 }} variant="body1"> Login to chat and get community tips about this and more deals. </Typography>
        });
    }

    return tabs;
};

type DealsDetailTabsProps = {
    currentDeal: DealReadModel;
};

export const DealsDetailTabs = ({ currentDeal }: DealsDetailTabsProps) => {
    
  const { appUser } = useAppAuth();
  
  const [currentTab, setCurrentTab] = useState(DealDetailTabs.Ads.toLowerCase());

    return (
        <Card>
            <Tabs variant="scrollable" value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)} scrollButtons="auto" sx={{ px: 3, bgcolor: 'background.neutral' }}>
                {resolveDealDetailTabs(currentDeal, appUser).map((tab) => (<Tab key={tab.key} value={tab.key} label={tab.heading} />))}
            </Tabs>
            
            {
                resolveDealDetailTabs(currentDeal, appUser).map((tab) => tab.key === currentTab && 
                (
                    <Box key={tab.key} sx={{ mt: 2 }}>
                        {tab.component}
                    </Box>
                ))
            }
        </Card>
    );
};
