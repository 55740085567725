import { object, SchemaOf, string } from 'yup';

import { AddBrandAffiliateRequest, BrandReadModel, UpdateBrandRequest } from 'api/brand';
import { COUNTRIES, URL_REGEX } from 'services';

// update brand
export interface UpdateBrandDetails extends Omit<UpdateBrandRequest, 'brandName' > {};

export const updateBrandDetailsValidationSchema: SchemaOf<UpdateBrandDetails> = object().shape({
    brandImageId: string().required('required'),
    description: string()
        .when("description", (val, schema) => {
            if (val?.length > 0) {
                return string()
                .min(1, 'description too short. atleast 1 characters')
                .max(280, 'description too long. Max 64 characters.' )
                .required('required')
            } else { 
                return string().notRequired();
            }
        }),
    website: string()
        .when("website", (val, schema) => {
            if (val?.length > 0) {
                return string().matches(URL_REGEX, 'Invalid url').required('required')
            } else { 
                return string().notRequired();
            }
        }),
    apiKey: string().required('required'),
}, [
    ["description", "description"],
    ["website", "website"],
]);

export const updateBrandDefaultValues = (brandData: BrandReadModel) => {
    console.log(brandData)
    return {
        website: brandData?.website || '',
        description: brandData?.description || '',
        brandImageId: brandData?.brandPicId || '',
        apiKey: ''
    }
};

export const resolveUpdatedFields = (values: UpdateBrandDetails, currentBrandData: BrandReadModel, imageId: string | undefined) => {

    var updatedFields: Record<string,any> = {};
    
    if (imageId && imageId !== currentBrandData.brandPicId) {
        updatedFields.brandImageId = imageId;
    }
    
    if (values.website && values.website !== currentBrandData.website) {
        updatedFields.website = values.website;
    }
    
    if (values.description && values.description !== currentBrandData.description) {
        updatedFields.bio = values.description;
    }

    return updatedFields;
}

// affiliate links
export interface AddBrandAffiliateDetails extends Omit<AddBrandAffiliateRequest, 'id' | 'brandName' | 'recaptchaToken' > {};

export const addBrandAffiliateValidationSchema: SchemaOf<AddBrandAffiliateDetails> = object().shape({
    apiKey: string().required(),
    name: string().required(),
    link: string().matches(URL_REGEX, 'Invalid url').required('required'),
    countryCode: string().oneOf(Object.values(COUNTRIES.map(x => x.code))).required('required'),
    countryName: string().oneOf(Object.values(COUNTRIES.map(x => x.label))).required('required'),
});

export const affilateLinkDefaultValues = {
    apiKey: '',
    name: '',
    link: '',
    countryCode: '',
    countryName: '',
}