import { appConfig } from "config";
import { getCookie, saveCookie } from "utils";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAuthToken, saveAuthToken } from "./jwtTokenHelper";
import { CSRF_TOKEN_HEADER_NAME, MON_AMOUR_API_AUTH_HEADER } from "./constants";


export const AuthTokenRequestInterceptor = (requestConfig: AxiosRequestConfig) => {
    const { origin } = new URL(requestConfig.baseURL || '');
    const allowedOrigins = [ appConfig.plugsDealApi.baseUrl ];
    const authToken = getAuthToken();
    if (allowedOrigins.includes(origin) && authToken) {
        requestConfig.headers = {
            Authorization: `Bearer ${authToken}`,
            "x-plugs-deal-csrf-token": getCookie(CSRF_TOKEN_HEADER_NAME),
        }
    } else if (allowedOrigins.includes(origin)) {
        requestConfig.headers = {
            "x-plugs-deal-csrf-token": getCookie(CSRF_TOKEN_HEADER_NAME),
        }
    }

    return requestConfig;
};

export const AuthTokenResponseInterceptor = (response: AxiosResponse) => {
    
    // refresh auth token on every response if present
    const authToken = response.headers[MON_AMOUR_API_AUTH_HEADER];
    if (authToken) {
      saveAuthToken(authToken);
    };
    
    // return response as normal
    return response;
};

export const CSRFTokenResponseInterceptor = (response: AxiosResponse) => {
    
    // refresh auth token on every response if present
    const csrfToken = response.headers[CSRF_TOKEN_HEADER_NAME];
    if (csrfToken) {
      saveCookie(CSRF_TOKEN_HEADER_NAME, csrfToken);
    };
    
    // return response as normal
    return response;
};