import { Channel, Chat, Window } from "stream-chat-react";
import { AppPages } from "routes";
import { DealReadModel } from "api/deal";
import { useHandleApiError } from "hooks";
import Stack from "@mui/material/Stack/Stack";
import { UNMessageList } from "./UNMessageList";
import { UNMessageInput } from "./UNMessageInput";
import { useGetAccountChatToken } from "api/account";
import { MessagesLoadingIndicator } from "components";
import { AppChatProvider, useAppAuth, useAppChat } from "context";
import { resolveS3FileUrl } from "services";

type GeneralChatChannelProps = {
    currentDeal: DealReadModel;
};

export const GeneralChat = ({ currentDeal }: GeneralChatChannelProps) => {
    
    const { appUser, isLoading } = useAppAuth();
  
    // get app user chat token
    const { data: chatToken, isLoading: isLoadingChatToken, isError: isChatTokenError, error: chatTokenError } = useGetAccountChatToken({ id: appUser?.id }, !!appUser);
    useHandleApiError(isChatTokenError, chatTokenError, AppPages.Home);

    return (
        <>
        {
            !isLoadingChatToken && chatToken && !isLoading && appUser && currentDeal ?

            <AppChatProvider chatToken={chatToken.token} appUser={appUser} currentDeal={currentDeal}>
                <GeneralChatChannel currentDeal={currentDeal} />
            </AppChatProvider>

            :
            
            <MessagesLoadingIndicator />
        }
        </>
    );
};

const GeneralChatChannel = ({ currentDeal }: GeneralChatChannelProps) => {

    const { chatClient, generalChatChannel } = useAppChat();

    const resolveGeneralChatChannelId = (currentDeal: DealReadModel) => `tpd_gc_${currentDeal.id}`;

    if (!chatClient || !generalChatChannel) {
        return <MessagesLoadingIndicator />
    };

    if (!currentDeal) {
        return <>Something went wrong, please refresh your page.</>
    };

    const gcChannel = chatClient.channel('livestream', resolveGeneralChatChannelId(currentDeal), {
        image: resolveS3FileUrl(currentDeal?.dealPicId),
        name: currentDeal.title,
        dealOwnerId: currentDeal.accountId,
        dealId: currentDeal.id
    });

    return (
        <Stack sx={{ height: 490 }}>
            <Chat client={chatClient}> 
                <Channel channel={gcChannel}>
                    <Window>
                        <UNMessageList />
                        <UNMessageInput />
                    </Window>
                </Channel>
            </Chat>
        </Stack>
    );
};