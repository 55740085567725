import { AppUser } from './app-user';
import { useGetAccount } from 'api/account';
import { useLocalStorage } from 'usehooks-ts';
import { TPD_CONSTANT_PREFIX, ls_LOGIN_REDIRECT_FROM_PATH } from 'utils';
import { createContext, useContext, useEffect, useState } from 'react';
import { resolveJwtPayload, saveAuthToken, jwtTokenIsValid, clearAuthToken, getAuthToken } from 'api/jwtTokenHelper';

export interface AppAuthContextInterface {
    appUser: AppUser;
    isError: boolean;
    accountId: string;
    isLoading: boolean;
    logOut: () => void;
    isAuthenticated: boolean;
    loginRedirectPath: string;
    setAppUser: (account: AppUser) => void;
    setLoginRedirectPath: (redirectPath?: string) => void;
    loginAccountWithAuthToken: (access_token: string) => void;
};

const AppAuthContext = createContext<AppAuthContextInterface | undefined>(undefined);

const useAppAuth = (): AppAuthContextInterface => {
    const validAuthCtx = useContext(AppAuthContext);

    if (validAuthCtx === undefined) {
        throw new Error ("invalid app auth context.");
    };

    return validAuthCtx;
};

const logOutAppUser = () => {
    clearAuthToken();

    // explicitly remove auth keys
    resetLoginRedirectPath();

    // clear all plug deal keys from local storage
    Object.keys(localStorage).filter(x => x.startsWith(TPD_CONSTANT_PREFIX)).forEach(x => window.localStorage.removeItem(x));
};

const resetLoginRedirectPath = () => window.localStorage.removeItem(ls_LOGIN_REDIRECT_FROM_PATH);

const useProvideAuth = (): AppAuthContextInterface => {
    const [accountId, setAccountId] = useState<string | undefined>(undefined);
    const [appUser, setAppUser] = useState<AppUser | undefined>(undefined);
    const [isAuthenticated, setIsAuthenticated] = useState(jwtTokenIsValid());
    const [authToken, setAuthToken] = useState<string | undefined>(getAuthToken());

    // handle user login
    const loginAccountWithAuthToken = (access_token: string) => {

        // persist auth token cookie
        setAuthToken(access_token);

        // save token in state to trigger re-renders
        saveAuthToken(access_token);
        
        // pre resolve account authentication based on new token in cookie
        setIsAuthenticated(jwtTokenIsValid());

        // reset redirect path
        setLoginRedirectPath('');
        resetLoginRedirectPath();
    };
    
    // handle login redirect
    const [loginRedirectPath, setLoginRedirectPath] = useLocalStorage<string>(ls_LOGIN_REDIRECT_FROM_PATH, '');
    const handleSetLoginRedirect = (redirectPath?: string) => redirectPath && setLoginRedirectPath(redirectPath);

    // re authenticate on token change
    useEffect(() => {
        if (authToken) {
            saveAuthToken(authToken);
            setAccountId(resolveJwtPayload()?.accountId);
        };
        
        setIsAuthenticated(jwtTokenIsValid());
    }, [ authToken ]);

    // get account data for app user
    const { data: appUserData, isLoading, isError } = useGetAccount({ id: accountId as string }, isAuthenticated);
    useEffect(() => {
        if (appUserData) {
            setAppUser(appUserData);
        };
    }, [ appUserData ]);
    
    
    const handleLoginCheck = () => {
        const isAuthValid = jwtTokenIsValid();
        setIsAuthenticated(isAuthValid);
        
        if (isAuthValid !== true) {
            logOutAppUser();
        };
    };

    // verify auth status every 1/2 second
    useEffect(() => {
        
        // check if user is still logged in every 500ms and handle accordingly
        const loginCheckInterval = setInterval(() => handleLoginCheck(), 500);

        return () => {
            clearInterval(loginCheckInterval);
        };
    });

    return {
        appUser,
        isError,
        accountId,
        isLoading,
        setAppUser,
        isAuthenticated,
        loginRedirectPath,
        logOut: logOutAppUser,
        resetLoginRedirectPath,
        loginAccountWithAuthToken,
        setLoginRedirectPath: handleSetLoginRedirect
    } as AppAuthContextInterface;
};

const AppAuthProvider = ({children}: {children: React.ReactNode}) => {
    const appAuthContextValue = useProvideAuth();
    
    return (
        <>
            <AppAuthContext.Provider value={appAuthContextValue}>
                {children}
            </AppAuthContext.Provider>
        </>
    );
};

export { AppAuthProvider, useAppAuth };